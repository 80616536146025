import axios from 'axios';
import $ from 'jquery';

const backendIpUrl = process.env.REACT_APP_BACKEND_IP_URL;
const backendUrl = process.env.REACT_APP_BACKEND_URL;

export const getIp = () => new Promise((resolve, reject) => {
  try {
    const res = axios.get(`${backendIpUrl}?format=json`);
    return resolve(res);
  } catch (err) {
    return reject(err);
  }
});

export const getAnasayfaBildirimleri = () => new Promise((resolve, reject) => {
  try {
    const res = axios.get(`${backendUrl}anasayfaBildirimleri`);
    return resolve(res);
  } catch (err) {
    return reject(err);
  }
});

export const getAnasayfaDuyurulari = () => new Promise((resolve, reject) => {
  try {
    const res = axios.get(`${backendUrl}anasayfaDuyurulari`);
    return resolve(res);
  } catch (err) {
    return reject(err);
  }
});

export const getDuyurular = () => new Promise((resolve, reject) => {
  try {
    const res = axios.get(`${backendUrl}duyurular`);
    return resolve(res);
  } catch (err) {
    return reject(err);
  }
});

export const getFirmaSayisi = () => new Promise((resolve, reject) => {
  try {
    const res = axios.get(`${backendUrl}firmaSayisi`);
    return resolve(res);
  } catch (err) {
    return reject(err);
  }
});

export const getDtoBildirimSayisi = () => new Promise((resolve, reject) => {
  try {
    const res = axios.get(`${backendUrl}dtoBildirimSayisi`);
    return resolve(res);
  } catch (err) {
    return reject(err);
  }
});

export const getAbBildirimSayisi = () => new Promise((resolve, reject) => {
  try {
    const res = axios.get(`${backendUrl}abBildirimSayisi`);
    return resolve(res);
  } catch (err) {
    return reject(err);
  }
});

export const getUlkeler = () => new Promise((resolve, reject) => {
  try {
    const res = axios.get(`${backendUrl}ulkeler`);
    return resolve(res);
  } catch (err) {
    return reject(err);
  }
});

export const getGuncelBildirimler = (ulkeAd) => new Promise((resolve, reject) => {
  try {
    const res = axios.get(`${backendUrl}guncelBildirimler/${ulkeAd}`);
    return resolve(res);
  } catch (err) {
    return reject(err);
  }
});

export const getAbBildirimler = (urunGrubu) => new Promise((resolve, reject) => {
  try {
    const res = axios.get(`${backendUrl}ab/${urunGrubu}`);
    return resolve(res);
  } catch (err) {
    return reject(err);
  }
});

export const getUlkeListesi = () => new Promise((resolve, reject) => {
  try {
    const res = axios.get(`${backendUrl}ulkeListesi`);
    return resolve(res);
  } catch (err) {
    return reject(err);
  }
});

export const getSehirListesi = () => new Promise((resolve, reject) => {
  try {
    const res = axios.get(`${backendUrl}sehirListesi`);
    return resolve(res);
  } catch (err) {
    return reject(err);
  }
});

export const getFasilaBasliklar = () => new Promise((resolve, reject) => {
  try {
    const res = axios.get(`${backendUrl}fasilaBasliklar`);
    return resolve(res);
  } catch (err) {
    return reject(err);
  }
});

export const getKitaUlkeler = () => new Promise((resolve, reject) => {
  try {
    const res = axios.get(`${backendUrl}kitaUlkeler`);
    return resolve(res);
  } catch (err) {
    return reject(err);
  }
});

export const getArsivBildirimler = (formData) => new Promise((resolve, reject) => {
  try {
    const res = axios.get(`${backendUrl}search/${JSON.stringify(formData)}/json`);
    return resolve(res);
  } catch (err) {
    return reject(err);
  }
});

export const getUlkeDetay = (ulkeAd) => new Promise((resolve, reject) => {
  try {
    const res = axios.get(`${backendUrl}ulke/${ulkeAd}`);
    return resolve(res);
  } catch (err) {
    return reject(err);
  }
});

export const getIcerik = (icerik) => new Promise((resolve, reject) => {
  try {
    const res = axios.get(`${backendUrl}sayfalar/${icerik}`);
    return resolve(res);
  } catch (err) {
    return reject(err);
  }
});

export const getYorumYap = (baslik, yorum, bildirimId, sifreId) => new
Promise((resolve, reject) => {
  try {
    const res = axios.get(
      `${backendUrl}yorumKaydet/${baslik}/${yorum}/${bildirimId}/${sifreId}`,
    );
    return resolve(res);
  } catch (err) {
    return reject(err);
  }
});

export const getTamMetinIstek = (bildirimId, sifreId) => new Promise((resolve, reject) => {
  try {
    const res = axios.get(
      `${backendUrl}tamMetinIstek/${bildirimId}/${sifreId}`,
    );
    return resolve(res);
  } catch (err) {
    return reject(err);
  }
});

export const getRegister = (key) => new Promise((resolve, reject) => {
  console.log(`trying to register with key${key}`);
  try {
    const res = axios.get(`${backendUrl}registerCheck/${key}/kontrol`);
    return resolve(res);
  } catch (err) {
    return reject(err);
  }
});

export const login = (username, password) => new Promise((resolve, reject) => {
  try {
    const res = axios.get(`${backendUrl}login/${username}/${password}`);
    return resolve(res);
  } catch (err) {
    return reject(err);
  }
});

export const forgot = (email) => new Promise((resolve, reject) => {
  try {
    const res = axios.post(`${backendUrl}sifreYenile`,
      `mailAdresi=${email}`);
    return resolve(res);
  } catch (err) {
    return reject(err);
  }
});

export const register = (user) => new Promise((resolve, reject) => {
  console.log(JSON.stringify(user));
  try {
    $.ajax({
      type: 'POST',
      url: `${backendUrl}register`,
      data: JSON.stringify(user),
      success(res) {
        return resolve(res);
      },
    });
  } catch (err) {
    return reject(err);
  }
  return '';
});

export const postIletisimFormu = (formData) => new Promise((resolve, reject) => {
  try {
    $.ajax({
      type: 'POST',
      url: `${backendUrl}mesaj`,
      data: JSON.stringify(formData),
      success(msg) {
        return resolve(msg);
      },
    });
  } catch (err) {
    return reject(err);
  }
  return '';
});

export const postAnketFormu = (formData) => new Promise((resolve, reject) => {
  try {
    $.ajax({
      type: 'POST',
      url: `${backendUrl}anket`,
      data: JSON.stringify(formData),
      success(msg) {
        return resolve(msg);
      },
    });
  } catch (err) {
    return reject(err);
  }
  return '';
});

export const postTeknikEngelForm = (formData) => new Promise((resolve, reject) => {
  try {
    $.ajax({
      type: 'POST',
      url: `${backendUrl}teknikEngel`,
      data: JSON.stringify(formData),
      success(msg) {
        return resolve(msg);
      },
    });
  } catch (err) {
    return reject(err);
  }
  return '';
});

export const postMevzuatTalep = (formData) => new Promise((resolve, reject) => {
  try {
    $.ajax({
      type: 'POST',
      url: `${backendUrl}mevzuatTalep`,
      data: JSON.stringify(formData),
      success(msg) {
        return resolve(msg);
      },
    });
  } catch (err) {
    return reject(err);
  }
  return '';
});

export const postProfil = (formData) => new Promise((resolve, reject) => {
  try {
    $.ajax({
      type: 'POST',
      url: `${backendUrl}firma`,
      data: JSON.stringify(formData),
      success(msg) {
        return resolve(msg);
      },
    });
  } catch (err) {
    return reject(err);
  }
  return '';
});

export const postUyeliktenAyril = (formData) => new Promise((resolve, reject) => {
  try {
    $.ajax({
      type: 'POST',
      url: `${backendUrl}uyeliktenAyril`,
      data: JSON.stringify(formData),
      success(msg) {
        return resolve(msg);
      },
    });
  } catch (err) {
    return reject(err);
  }
  return '';
});

export const postGtipIkiListesi = (formData) => new Promise((resolve, reject) => {
  try {
    $.ajax({
      type: 'POST',
      url: `${backendUrl}gtipIkiListesi`,
      data: JSON.stringify(formData),
      success(msg) {
        return resolve(msg);
      },
    });
  } catch (err) {
    return reject(err);
  }
  return '';
});

export default getIp;
