import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import 'react-calendar/dist/Calendar.css';
import './App.scss';

import About from './pages/Iletisim';
import withScrollToTop from './components/withScrollToTop';
import Home from './pages/Home';
import Bildirimler from './pages/Bildirimler';
import AbBildirimler from './pages/AbBildirimler';
import TeknikEngelNedir from './pages/TeknikEngelNedir';
import TeknikEngelAsmaAnahtari from './pages/TeknikEngelAsmaAnahtari';
import BilgiyeUlasin from './pages/BilgiyeUlasin';
import TeknikEngelForm from './pages/TeknikEngelForm';
import Duyurular from './pages/Duyurular';
import Dunya from './pages/Dunya';
import Ulke from './pages/Ulke';
import FirmaBilgileri from './pages/FirmaBilgileri';
import Register from './pages/Register';

const RouteWithScrollToTop = withScrollToTop(Route);

function App() {
  const [user, setUser] = useState({});
  useEffect(async () => {
    const currentUser = await localStorage.getItem('user');
    setUser(JSON.parse(currentUser));
  }, []);

  const handleLogin = (userLogin) => {
    setUser(userLogin);
    localStorage.setItem('user', JSON.stringify(userLogin));
  };

  /* const handleSignOut = () => {
    setUser({});
    localStorage.removeItem('user');
  }; */

  return (
    <div id="app-top" className="App">
      <Router>
        <Switch>
          <RouteWithScrollToTop path="/iletisim">
            <About />
          </RouteWithScrollToTop>
          <RouteWithScrollToTop path="/bildirimler/:bildirimId">
            <Bildirimler />
          </RouteWithScrollToTop>
          <RouteWithScrollToTop path="/bildirimler">
            <Bildirimler />
          </RouteWithScrollToTop>
          <RouteWithScrollToTop path="/abBildirimler">
            <AbBildirimler />
          </RouteWithScrollToTop>
          <RouteWithScrollToTop path="/dunya/:kita">
            <Dunya />
          </RouteWithScrollToTop>
          <RouteWithScrollToTop path="/register/:key/:to">
            <Register />
          </RouteWithScrollToTop>
          <RouteWithScrollToTop path="/dunya">
            <Dunya />
          </RouteWithScrollToTop>
          <RouteWithScrollToTop path="/duyurular/:duyuruId">
            <Duyurular />
          </RouteWithScrollToTop>
          <RouteWithScrollToTop path="/duyurular">
            <Duyurular />
          </RouteWithScrollToTop>
          <RouteWithScrollToTop path="/firmaBilgileri">
            <FirmaBilgileri />
          </RouteWithScrollToTop>
          <RouteWithScrollToTop path="/teknik-engel-nedir">
            <TeknikEngelNedir />
          </RouteWithScrollToTop>
          <RouteWithScrollToTop path="/teknik-engel-asma-anahtari">
            <TeknikEngelAsmaAnahtari />
          </RouteWithScrollToTop>
          <RouteWithScrollToTop path="/mevzuatTalep">
            <BilgiyeUlasin />
          </RouteWithScrollToTop>
          <RouteWithScrollToTop path="/teknikEngel">
            <TeknikEngelForm />
          </RouteWithScrollToTop>
          <RouteWithScrollToTop exact path="/ulke/:ulkeAd">
            <Ulke handleLogin={handleLogin} user={user} />
          </RouteWithScrollToTop>
          <RouteWithScrollToTop exact path="/ulke">
            <Ulke handleLogin={handleLogin} user={user} />
          </RouteWithScrollToTop>
          <RouteWithScrollToTop path="/">
            <Home />
          </RouteWithScrollToTop>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
