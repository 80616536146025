import React from 'react';
import PropTypes from 'prop-types';
import LoginModal from './LoginModal';
import SignupModal from './SignupModal';
import ForgotModal from './ForgotModal';
import SuccessModal from './SuccessModal';

const Modal = (props) => {
  const [modalState, setModalState] = React.useState(0);
  const { closeLoginModal, alertVer, handleLogin } = props;
  const handleModalStateChange = (newState) => {
    setModalState(newState);
  };
  switch (modalState) {
    case 0:
      return (
        <LoginModal
          closeLoginModal={closeLoginModal}
          changeModalState={handleModalStateChange}
          handleLogin={handleLogin}
        />
      );
    case 1:
      return (
        <SignupModal
          closeLoginModal={closeLoginModal}
          changeModalState={handleModalStateChange}
          alertVer={alertVer}
        />
      );
    case 2:
      return (
        <ForgotModal
          closeLoginModal={closeLoginModal}
          changeModalState={handleModalStateChange}
          alertVer={alertVer}
        />
      );
    case 3:
      return (
        <SuccessModal
          closeLoginModal={closeLoginModal}
          changeModalState={handleModalStateChange}
        />
      );
    default:
      return (
        <LoginModal
          closeLoginModal={closeLoginModal}
          changeModalState={handleModalStateChange}
          handleLogin={handleLogin}
        />
      );
  }
};

Modal.propTypes = {
  alertVer: PropTypes.objectOf.isRequired,
  closeLoginModal: PropTypes.objectOf.isRequired,
  handleLogin: PropTypes.objectOf.isRequired,
};

export default Modal;
