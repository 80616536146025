import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getFasilaBasliklar } from '../../service/service';

function FasilaBaslikListesi(props) {
  const [fasilaBasliklar, setFasilaBasliklar] = useState([]);
  const { problem, handleInputChange, value } = props;
  useEffect(() => getFasilaBasliklar()
    .then((res) => {
      if (res && res.data) {
        setFasilaBasliklar(res.data);
      }
    }),
  []);
  return (
    <select
      className={`form-control 
        ${problem && 'is-invalid'}`}
      onChange={handleInputChange}
      value={value}
    >
      <option>Ürün Grubu Seçiniz</option>
      {fasilaBasliklar.map((item) => (<option key={`ulke${item.id}`} value={item.id}>{`${item.baslikAralik} - ${item.ad}`}</option>))}
    </select>
  );
}

FasilaBaslikListesi.propTypes = {
  problem: PropTypes.objectOf.isRequired,
  handleInputChange: PropTypes.objectOf.isRequired,
  value: PropTypes.objectOf.isRequired,
};

export default FasilaBaslikListesi;
