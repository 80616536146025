import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getSehirListesi } from '../../service/service';

function SehirListesi(props) {
  const [sehirler, setSehirler] = useState([]);
  const { selected, handleInputChange } = props;
  useEffect(() => getSehirListesi()
    .then((res) => {
      if (res && res.data) {
        setSehirler(res.data);
      }
    }),
  []);
  return (
    <select className="form-control" value={selected} onChange={handleInputChange}>
      <option>Şehir Seçiniz</option>
      {sehirler.map((item) => (<option value={item.id} key={item.id}>{item.ad}</option>))}
    </select>
  );
}

SehirListesi.propTypes = {
  selected: PropTypes.objectOf.isRequired,
  handleInputChange: PropTypes.objectOf.isRequired,
};

export default SehirListesi;
