import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha,
} from 'react-simple-captcha';
import FasilaBaslikListesi from '../components/FasilaBaslikListesi';
import UlkeListesi from '../components/UlkeListesi';
import { postTeknikEngelForm } from '../service/service';
import { Button } from '../stories/Buttons/Button';
import withHeader from './withHeader';
import { Textarea } from '../stories/Form/Form';

function TeknikEngelForm(props) {
  useEffect(() => {
    loadCaptchaEnginge(6);
  }, []);

  const [secilenUlke, setSecilenUlke] = useState();
  const [secilenKonu, setSecilenKonu] = useState();
  const [secilenUrunGrubu, setSecilenUrunGrubu] = useState(0);
  const [mesaj, setMesaj] = useState('');
  const [ulkeProblem, setUlkeProblem] = useState(false);
  const [ugProblem, setUgProblem] = useState(false);
  const [mesajProblem, setMesajProblem] = useState(false);
  const [konuProblem, setKonuProblem] = useState(false);
  const [captchaProblem, setCaptchaProblem] = useState(false);
  const { alertVer } = props;

  const handleInputChange = (fieldType) => (event) => {
    switch (fieldType) {
      case 'ulke':
        setSecilenUlke(event.target.value);
        break;
      case 'konu':
        setSecilenKonu(event.target.value);
        break;
      case 'urunGrubu':
        setSecilenUrunGrubu(event.target.value);
        break;
      case 'mesaj':
        setMesaj(event.target.value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault(true);
    const userCaptchaValue = document.getElementById('user_captcha_input').value;
    if (validateCaptcha(userCaptchaValue, false) === true) {
      setCaptchaProblem(false);
    } else {
      setCaptchaProblem(true);
      return;
    }
    setUlkeProblem(!secilenUlke);
    setUgProblem(!secilenUrunGrubu);
    setMesajProblem(!mesaj);
    setKonuProblem(!secilenKonu);
    if (!secilenUrunGrubu || !secilenKonu || !secilenUlke || !mesaj) { return; }
    const formData = {
      ulkeId: secilenUlke,
      konu: secilenKonu,
      urun: secilenUrunGrubu,
      mesaj,
    };
    postTeknikEngelForm(formData)
      .then((res) => {
        if (res && res === 'Teknik Engel Bildiriminiz Kaydedilmistir.') {
          alertVer('Teknik Engel Bildiriminiz Kaydedilmistir', 'Bildiriminiz kaydedilmiş olup konu hakkında en kısa sürede tarafınıza bilgi verilecektir.', 'success');
          setSecilenUlke('');
          setSecilenUrunGrubu(0);
          setMesaj('');
          setSecilenKonu('');
        } else {
          alertVer('Form kaydetmede hata oluştu.', res, 'warning');
        }
      })
      .catch((err) => {
        alertVer('Form kaydetmede hata oluştu.', err, 'danger');
      });
  };
  return (
    <div>
      <div className="page-content">
        <div className="container">
          <div className="row justify-content-center text-center mt-5">
            <div className="col-12 col-lg-10">
              <form className="row" method="post" action="#" noValidate>
                <div className="form-group col-md-6">
                  <UlkeListesi handleInputChange={handleInputChange('ulke')} secilenUlke={secilenUlke} problem={ulkeProblem} />
                  {ulkeProblem
                    && (
                    <div className="invalid-feedback">
                      Lütfen ülke seçiniz.
                    </div>
                    )}
                </div>
                <div className="form-group col-md-6">
                  <select
                    className={`form-control 
                    ${konuProblem && 'is-invalid'}`}
                    onChange={handleInputChange('konu')}
                    value={secilenKonu}
                  >
                    <option>Konu Seçiniz</option>
                    <option style={{ display: 'none' }} value="">İlgili Konu</option>
                    <option value="Ürün özelliğine ilişkin şartlar">Ürün özelliğine ilişkin şartlar</option>
                    <option value="İşaretleme şartı">İşaretleme şartı</option>
                    <option value="Etiketleme şartı">Etiketleme şartı</option>
                    <option value="Ambalajlama şartı">Ambalajlama şartı</option>
                    <option value="Test, muayene ve karantina şartı">Test, muayene ve karantina şartı</option>
                    <option value="Bilgi şartı">Bilgi şartı</option>
                    <option value="Transite ilişkin şartlar">Transite ilişkin şartlar</option>
                    <option value="İhtisas gümrüklerinden geçme şartı">İhtisas gümrüklerinden geçme şartı</option>
                    <option value="Sevk öncesi inceleme">Sevk öncesi inceleme</option>
                    <option value="Diğer">Diğer </option>
                  </select>
                  {konuProblem
                    && (
                    <div className="invalid-feedback">
                      Lütfen konu seçiniz.
                    </div>
                    )}
                </div>
                <div className="form-group col-md-12">
                  <FasilaBaslikListesi handleInputChange={handleInputChange('urunGrubu')} value={secilenUrunGrubu} problem={ugProblem} />
                  {ugProblem
                    && (
                    <div className="invalid-feedback">
                      Lütfen ürün grubu seçiniz.
                    </div>
                    )}
                </div>
                <div className="form-group col-md-12">
                  <Textarea
                    onChange={handleInputChange('mesaj')}
                    value={mesaj}
                    name="message"
                    check={`
                    ${mesajProblem && 'is-invalid'}`}
                    placeholder="Mesajınız"
                    rows="4"
                  >
                    {mesajProblem
                    && (
                    <div className="invalid-feedback">
                      Mesaj alanı boş olamaz.
                    </div>
                    )}
                  </Textarea>
                </div>
                <div className="form-group col-md-12">
                  <LoadCanvasTemplate reloadText="Metni değiştir" />
                  <input
                    id="user_captcha_input"
                    name="user_captcha_input"
                    type="text"
                    placeholder="Resimdekileri giriniz"
                    className={`form-control 
                    ${captchaProblem && 'is-invalid'}`}
                    onChange={handleInputChange('rePass')}
                  />
                  {captchaProblem
                && (
                <div className="invalid-feedback">
                  Lütfen aynı şekilde yazınız
                </div>
                )}
                </div>

                <div className="col-md-12 text-right mt-4 mb-4">

                  <Button onClick={handleSubmit} text="Gönder" />

                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

TeknikEngelForm.propTypes = {
  alertVer: PropTypes.objectOf.isRequired,
};

export default withHeader(TeknikEngelForm, 'Ticarette Teknik Engel Bildirim Formu');
