import React, { Component } from 'react';
import am4geodata_worldLow from '@amcharts/amcharts4-geodata/worldLow';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4maps from '@amcharts/amcharts4/maps';
import PropTypes from 'prop-types';

class WorldMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // ulkeSec: props.ulkeSec,
    };
  }

  componentDidUpdate() {
    const chart = am4core.create('chartdiv', am4maps.MapChart);
    const { ulkeler } = this.props;

    // Set map definition
    chart.geodata = am4geodata_worldLow;

    // Set projection
    chart.projection = new am4maps.projections.Miller();

    // Create map polygon series
    const polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());

    const trler = ulkeler.map((item) => ({
      id: item.mapId,
      tr: item.ad,
      fill: am4core.color('#035FB5'),
    }));
    polygonSeries.data = trler;
    // Make map load polygon (like country names) data from GeoJSON
    polygonSeries.useGeodata = true;

    polygonSeries.exclude = ['AQ'];

    // Configure series
    const polygonTemplate = polygonSeries.mapPolygons.template;
    polygonTemplate.fill = am4core.color('#75A3FA'); // disable
    polygonTemplate.tooltipText = '{tr}';
    polygonTemplate.propertyFields.fill = 'fill';
    const ulkeSec2 = this.ulkeSec.bind(this);
    polygonTemplate.events.on('hit', (ev) => {
      // zoom to an object
      if (ev.target.dataItem.dataContext.tr) {
        ev.target.series.chart.zoomToMapObject(ev.target);
        ulkeSec2(ev.target.dataItem.dataContext.id);
      }
      // get object info
      // console.log(ev.target.dataItem.dataContext);
    });

    polygonTemplate.events.on('over', (ev) => {
      // ev.target.mapPolygons.each(function(polygon) {
      //   polygon.setState("highlight");
      // });
      ev.target.polygon.setState('highlight');
    });

    // Create hover state and set alternative fill color
    // var hs = polygonTemplate.states.create("hover");
    // hs.properties.fill = am4core.color("#043FAB");
    // hs.properties.fill = am4core.color("hoverColor");

    function over(ev) {
      ev.target.mapPolygons.each((polygon) => {
        polygon.setState('highlight');
      });
    }

    function out(ev) {
      ev.target.mapPolygons.each((polygon) => {
        polygon.setState('default');
      });
    }

    polygonSeries.events.on('over', over);
    polygonSeries.events.on('out', out);
    this.chart = chart;
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  ulkeSec(kod) {
    const { ulkeSec } = this.props;
    ulkeSec(kod);
  }

  render() {
    return (
      <div id="chartdiv" style={{ width: '100%', height: '800px' }} />
    );
  }
}

export default WorldMap;

WorldMap.propTypes = {
  ulkeSec: PropTypes.func.isRequired,
  ulkeler: PropTypes.objectOf.isRequired,
};
