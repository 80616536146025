import React from 'react';
import PropTypes from 'prop-types';
import check from '../../../icons/icn_check.svg';
import error from '../../../icons/icn_error.svg';

const SuccessModal = (props) => {
  const {
    closeLoginModal, alertType, alertMesaj, alertBaslik,
  } = props;

  const handleCloseLogin = () => {
    closeLoginModal();
  };
  return (
    // <!-- Modal Check -->
    <div className="modal-content text-primary">
      <div className="modal-header">
        <h5 className="modal-title" id="ForgotPasswordLabel">{alertBaslik}</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleCloseLogin}>
          <span aria-hidden="false">&times;</span>
        </button>
      </div>
      <div className="modal-body modal-check text-center mt-4">
        <div>
          {alertType && alertType === 'success'
            && <img src={check} alt="" />}
          {alertType && alertType === 'danger'
                && <img src={error} alt="" /> }
        </div>
        <h4 className="mt-4 mb-4">{alertMesaj || 'Aktivasyon Linki Mail Adresinize Gönderilmiştir'}</h4>

      </div>
    </div>
  );

  // <!-- Modal Error -->
  // <div className="modal-content text-danger">
  //     <div className="modal-header">
  //     <h5 className="modal-title" id="ForgotPasswordLabel">{props.alertBaslik}</h5>
  //         <button type="button" className="close" data-dismiss="modal" aria-label="Close"
  // onClick={handleCloseLogin}>
  //              <span aria-hidden="false">&times;</span>
  //          </button>
  //     </div>
  //     <div className="modal-body modal-check text-center mt-4">
  //         <div>
  //             <img src={error} />
  //         </div>
  //         <h4 className="mt-4 mb-4">{props.alertMesaj ||
  // 'Aktivasyon Linki Mail Adresinize Gönderilmiştir'}</h4>

  //     </div>
  // </div>
};

SuccessModal.propTypes = {
  closeLoginModal: PropTypes.objectOf.isRequired,
  alertType: PropTypes.objectOf.isRequired,
  alertMesaj: PropTypes.objectOf.isRequired,
  alertBaslik: PropTypes.objectOf.isRequired,
};

export default SuccessModal;
