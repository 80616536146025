import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getYorumYap } from '../service/service';

const YorumModal = (props) => {
  const [baslik, setBaslik] = useState('');
  const [yorum, setYorum] = useState('');
  const [baslikProblem, setBaslikProblem] = useState(false);
  const [yorumProblem, setYorumProblem] = useState(false);
  const {
    closeYorumModal, bildirimId, sifreId, alertVer,
  } = props;

  const handleCloseYorum = () => (event) => {
    event.preventDefault(true);
    closeYorumModal();
  };

  const handleYorumYap = () => {
    setBaslikProblem(!baslik);
    setYorumProblem(!yorum);
    if (!baslik || !yorum) {
      return;
    }
    getYorumYap(baslik, yorum, bildirimId, sifreId)
      .then((res) => {
        if (res && res.data) {
          alertVer('Yorumunuz alınmıştır', 'Yorumunuz alınmıştır', 'success');
          closeYorumModal();
        } else {
          alertVer('Yorum yaparken bir sorunla karşılaşıldı.', '', 'warning');
        }
      })
      .catch(() => {
        alertVer('Bağlantı sorunu oluştu.', '', 'danger');
      });
  };

  const handleInputChange = (fieldType) => (event) => {
    switch (fieldType) {
      case 'baslik':
        setBaslik(event.target.value);
        break;
      case 'yorum':
        setYorum(event.target.value);
        break;
      default:
        break;
    }
  };
  return (
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="loginLabel">Yorum Yap</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleCloseYorum()}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body p-3">
        <form id="login-form" method="post" noValidate>
          <div className="form-group">
            <input
              id="form_name"
              type="text"
              name="baslik"
              className={`form-control ${baslikProblem && 'is-invalid'}`}
              placeholder="Başlık"
              onChange={handleInputChange('baslik')}
            />
            {baslikProblem
              && (
              <div className="invalid-feedback">
                Başlık boş olamaz.
              </div>
              )}
          </div>
          <div className="form-group">
            <textarea
              id="form_password"
              type="textArea"
              rows="4"
              name="yorum"
              placeholder="Yorumunuz"
              className={`form-control ${yorumProblem && 'is-invalid'}`}
              onChange={handleInputChange('yorum')}
            />
            {yorumProblem
              && (
              <div className="invalid-feedback">
                Yorum boş olamaz.
              </div>
              )}
          </div>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a href="#" className="btn btn-primary btn-block" onClick={handleYorumYap}>Yorum Yap</a>
        </form>
      </div>
    </div>
  );
};

YorumModal.propTypes = {
  alertVer: PropTypes.objectOf.isRequired,
  closeYorumModal: PropTypes.objectOf.isRequired,
  bildirimId: PropTypes.objectOf.isRequired,
  sifreId: PropTypes.objectOf.isRequired,
};

export default YorumModal;
