/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { getRegister } from '../service/service';
import withHeader from './withHeader';

function Register() {
  const { key } = useParams();
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  console.log(key);
  console.log(loading);
  useEffect(() => getRegister(key)
    .then((res) => {
      if (res && res.data) {
        localStorage.setItem('user', JSON.stringify(res.data));
        setSuccess(true);
        setLoading(false);
      } else {
        setLoading(false);
      }
    })
    .catch(() => {
      setLoading(false);
    }), []);

  return (
    <>
      {loading && <div>loading</div>}
      {!loading && success && <Redirect to="/firmaBilgileri" />}
      {!loading && !success && <Redirect to="/" />}
    </>
  );
}

export default withHeader(Register);
