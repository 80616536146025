import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getUlkeListesi } from '../../service/service';

function UlkeListesi(props) {
  const [ulkeler, setUlkeler] = useState([]);
  const {
    problem, size, handleInputChange, secilenUlke, include, exclude,
  } = props;

  useEffect(() => getUlkeListesi()
    .then((res) => {
      if (res && res.data) {
        setUlkeler(res.data);
      }
    }),
  []);
  return (
    <select
      className={`form-control 
        ${problem && 'is-invalid'}`}
      size={size}
      onChange={handleInputChange}
      value={secilenUlke}
    >
      <option>Ülke Seçiniz</option>
      {ulkeler.filter((item) => {
        if (include) { return include.map(() => item.id).includes(item.id); }
        if (exclude) {
          return !exclude.map(() => item.id).includes(item.id);
        }
        return true;
      })
        .map((item) => (<option key={`ulke${item.id}`} value={item.id}>{item.ad}</option>))}
    </select>
  );
}

UlkeListesi.propTypes = {
  problem: PropTypes.objectOf.isRequired,
  size: PropTypes.objectOf.isRequired,
  handleInputChange: PropTypes.objectOf.isRequired,
  secilenUlke: PropTypes.objectOf.isRequired,
  include: PropTypes.objectOf.isRequired,
  exclude: PropTypes.objectOf.isRequired,
};

export default UlkeListesi;
