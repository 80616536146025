import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { forgot } from '../../../service/service';

const ForgotModal = (props) => {
  const [emailProblem, setEmailProblem] = useState(false);
  const [emailMessage, setEmailMessage] = useState('');
  const [email, setEmail] = useState('');
  const { closeLoginModal, changeModalState, alertVer } = props;
  const handleCloseLogin = () => {
    closeLoginModal();
  };

  const isValidEmail = (val) => {
    const regEmail = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return (regEmail.test(val));
  };

  const handleInputChange = (fieldType) => (event) => {
    switch (fieldType) {
      case 'email':
        setEmail(event.target.value);
        break;
      default:
        break;
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault(true);
    if (!email) {
      setEmailMessage('Kullanıcı adı boş olamaz.');
    } else if (!isValidEmail(email)) {
      setEmailProblem(true);
      setEmailMessage('Lütfen eposta adresi giriniz.');
      return;
    }
    setEmailProblem(!email);
    if (!email) {
      return;
    }
    forgot(email)
      .then((res) => {
        if (res && res.data && res.data.msg && res.data.msg === 'Yeni Şifreniz Mail Adresinize İletilmiştir') {
          // props.alertVer('İşlem Başarılı', 'Yeni Şifreniz Mail Adresinize İletilmiştir',
          // 'success');
          // handleCloseLogin();
          changeModalState(3);
        } else {
          setEmailProblem(true);
          setEmailMessage('Kullanıcı bulunamadı.');
        }
      })
      .catch(() => {
        alertVer('İşlem Başarısız', 'Bağlantıda bir sorun oluştu.', 'danger');
      });
  };
  return (
    // <!-- Modal Login -->
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="ForgotPasswordLabel">Şifre Yenileme</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleCloseLogin}>
          <span aria-hidden="false">&times;</span>
        </button>
      </div>
      <div className="modal-body p-3">
        <p>Şifre yenileme bağlantısını gönderebilmemiz için e-posta adresinize ihtiyacımız var.</p>
        <form id="forgot-form" method="post" noValidate>
          <div className="form-group">
            <input
              id="form_name"
              type="text"
              name="name"
              className={`form-control 
                    ${emailProblem && 'is-invalid'}`}
              placeholder="E-mail"
              onChange={handleInputChange('email')}
            />
            {emailProblem
            && (
            <div className="invalid-feedback">
              {emailMessage}
            </div>
            )}
          </div>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a href="#" className="btn btn-primary btn-block" onClick={handleSubmit}>Şifremi Yenile</a>
        </form>
      </div>
    </div>
  );
};

ForgotModal.propTypes = {
  closeLoginModal: PropTypes.objectOf.isRequired,
  changeModalState: PropTypes.objectOf.isRequired,
  alertVer: PropTypes.objectOf.isRequired,
};

export default ForgotModal;
