import React from 'react';
import PropTypes from 'prop-types';
import HeaderSection from './HeaderSection';
import HeaderNav2 from './HeaderNav2';

const Header = ({
  user, handleLogin, handleLogout, alertVer, disableHeaderSection,
  sectionHeading, anketShow, handleAnketClose,
}) => (
  <header
    className="site-header"
    data-parallax="scroll"
    data-speed="0.5"
  >
    <HeaderNav2
      user={user}
      handleLogin={handleLogin}
      handleLogout={handleLogout}
      alertVer={alertVer}
      anketShow={anketShow}
      closeAnketModal={handleAnketClose}
    />
    {!disableHeaderSection && (
    <HeaderSection
      sectionHeading={sectionHeading}
    />
    )}
  </header>
);

export default Header;

Header.propTypes = {
  user: PropTypes.string.isRequired,
  handleLogin: PropTypes.string.isRequired,
  handleLogout: PropTypes.string.isRequired,
  sectionHeading: PropTypes.string.isRequired,
  alertVer: PropTypes.string.isRequired,
  disableHeaderSection: PropTypes.string.isRequired,
  anketShow: PropTypes.bool.isRequired,
  handleAnketClose: PropTypes.func.isRequired,
};
