import React, { useEffect, useState } from 'react';
import { getAbBildirimler } from '../service/service';
import withHeader from './withHeader';
import search from '../icons/icn_search.svg';
import ab from '../images/ab-avrupa-birligi.jpg';

const appserver = process.env.REACT_APP_BACKEND_URL;
function AbBildirimler() {
  const [guncelAbBildirimler, setGuncelAbBildirimler] = useState([]);
  const [filtrelenmisBildirimler, setFiltrelenmisBildirimler] = useState([]);
  const [urunGrubu, setUrunGrubu] = useState(null);
  const [sayfaSayisi, setSayfaSayisi] = useState(0);
  const [seciliSayfa, setSeciliSayfa] = useState(1);
  const [isLoading, setLoading] = useState(true);
  const [filtre, setFiltre] = React.useState('');

  useEffect(() => getAbBildirimler(urunGrubu)
    .then((res) => {
      if (res && res.data) {
        setGuncelAbBildirimler(res.data);
        setFiltrelenmisBildirimler(res.data);
        setSayfaSayisi(Math.ceil(res.data.length / 6));
        setLoading(false);
      }
    }),
  []);

  const handleInputChange = (ug) => (event) => {
    event.preventDefault();
    setUrunGrubu(ug);
    setSeciliSayfa(1);
    getAbBildirimler(ug)
      .then((res) => {
        if (res && res.data) {
          setGuncelAbBildirimler(res.data);
          setFiltrelenmisBildirimler(res.data);
          setSayfaSayisi(Math.ceil(res.data.length / 6));
          setFiltre('');
        }
      });
  };

  const handleFilterChange = (event) => {
    setFiltre(event.target.value);
    const bildirimler = guncelAbBildirimler.filter((item) => {
      const query = filtre.toLowerCase();
      return ((item.kod && item.kod.toLowerCase().search(query) >= 0)
              || item.id === parseInt(filtre, 10)
              || (item.sayi && item.sayi === parseInt(filtre, 10))
              || (item.ulke && item.ulke.toLowerCase().search(query) > -1)
              || (item.urunGrubu && item.urunGrubu.toLowerCase().search(query) > -1)
      );
    });
    setFiltrelenmisBildirimler(bildirimler);
    setSayfaSayisi(Math.ceil(bildirimler.length / 6));
    setSeciliSayfa(1);
  };

  const sayfaDegistir = (menuIndex) => () => {
    setSeciliSayfa(menuIndex);
  };

  const ilkBildirim = () => (seciliSayfa - 1) * 6;

  const abBildirimler = () => {
    if (filtrelenmisBildirimler.length > 0) {
      return (
        <div className="row row-cols-1 row-cols-xl-3 row-cols-md-2 mt-2 dtoBildirimleriBox">
          {filtrelenmisBildirimler.slice(ilkBildirim(), ilkBildirim() + 6).map((item) => (
            <div className="col p-2">
              <div className="card h-100">
                <div className="card-header">
                  <img src={ab} alt="" />
                  {' '}
                  {item.ulke}
                </div>
                <div className="card-body p-0">
                  <h6 className="card-title">{item.urunGrubu}</h6>
                  <hr />
                  <div className="pl-2 pr-2">
                    <dl className="row">
                      <dt className="col">Yıl:</dt>
                      <dd className="col text-right">{item.yil}</dd>
                    </dl>
                    <dl className="row">
                      <dt className="col">Sayı:</dt>
                      <dd className="col text-right">{item.sayi}</dd>
                    </dl>
                    <dl className="row">
                      <dt className="col">Eklenme Tarihi:</dt>
                      <dd className="col text-right">{item.ilkTarih}</dd>
                    </dl>
                    <dl className="row">
                      <dt className="col">Form:</dt>
                      <dd className="col text-right">
                        {item.formIsim ? <a href={`${appserver}/downloadAb/${item.id}/${item.formIsim}`} className="card-link">{item.formIsim}</a> : 'Yüklenmemiş'}

                      </dd>
                    </dl>
                    <dl className="row">
                      <dt className="col">Taslak Metin:</dt>
                      <dd className="col text-right">
                        {item.taslakIsim ? <a href={`${appserver}/downloadAb/${item.id}/${item.taslakIsim}`} className="card-link">{item.taslakIsim}</a> : 'Yüklenmemiş' }
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      );
    } if (isLoading) {
      return (
        <div className="alert alert-primary" role="alert">
          Bildirimler Yükleniyor!
        </div>
      );
    }
    return (
      <div className="alert alert-primary" role="alert">
        Güncel Bildirim bulunmamaktadır!
      </div>
    );
  };

  const sayfalar = () => {
    const sayfaTuslari = [];
    let baslangic = 0;
    let son = 0;
    if (sayfaSayisi < 6) {
      baslangic = 1;
      son = sayfaSayisi;
    } else if (seciliSayfa - 2 < 1) {
      baslangic = 1;
      son = seciliSayfa + 3 + (3 - seciliSayfa);
    } else if (seciliSayfa + 3 > sayfaSayisi) {
      baslangic = (seciliSayfa - 2) - (3 - (sayfaSayisi - seciliSayfa));
      son = sayfaSayisi;
    } else {
      baslangic = (seciliSayfa - 2 > 0 ? seciliSayfa - 2 : 1);
      son = seciliSayfa + 3 > sayfaSayisi ? sayfaSayisi : seciliSayfa + 3;
    }

    for (let i = baslangic; i < son + 1; i += 1) {
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      sayfaTuslari.push(<li className={`page-item ${seciliSayfa === i && 'active'}`}><a href="#" className="page-link" onClick={sayfaDegistir(i)}>{i}</a></li>);
    }
    return sayfaTuslari;
  };

  const oncekiSayfa = () => {
    if (seciliSayfa > 1) {
      <li className="page-item">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a href="#" className="page-link" onClick={sayfaDegistir(seciliSayfa - 1)} aria-label="Previous">
          <span aria-hidden="true">&laquo;</span>
        </a>
      </li>;
    }
  };

  const sonrakiSayfa = () => {
    if (seciliSayfa < sayfaSayisi) {
      <li className="page-item">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a href="#" className="page-link" onClick={sayfaDegistir(seciliSayfa + 1)} aria-label="Next">
          <span aria-hidden="true">&raquo;</span>
        </a>
      </li>;
    }
  };

  const navigasyon = () => (
    <ul className="pagination">
      {oncekiSayfa()}
      {sayfalar()}
      {sonrakiSayfa()}
    </ul>
  );

  return (
    <div>
      <div className="container pt-4">
        <div className="row">
          <div className="col-12 col-lg-3">
            <div className="card mt-0 cardSideBoxTable">
              <div className="card-header">
                Ürün Grupları
              </div>
              <div className="card-body" style={{ fontSize: '0.8rem' }}>
                <dl className="row">
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <dt className="col"><a href="#" onClick={handleInputChange('B00 - Yapı Malzemeleri')}>B00 - Yapı Malzemeleri</a></dt>
                </dl>
                <dl className="row">
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <dt className="col"><a href="#" onClick={handleInputChange('C00A - Tarım, Balıkçılık ve Gıda')}>C00A - Tarım, Balıkçılık ve Gıda</a></dt>
                </dl>
                <dl className="row">
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <dt className="col"><a href="#" onClick={handleInputChange('C00C - Kimyasallar')}>C00C - Kimyasallar</a></dt>
                </dl>
                <dl className="row">
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <dt className="col"><a href="#" onClick={handleInputChange('C00P - İlaç ve Kozmetik')}>C00P - İlaç ve Kozmetik</a></dt>
                </dl>
                <dl className="row">
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <dt className="col"><a href="#" onClick={handleInputChange('H00 - Eğlence Ekipmanları')}>H00 - Eğlence Ekipmanları</a></dt>
                </dl>
                <dl className="row">
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <dt className="col"><a href="#" onClick={handleInputChange('I00 - MAKİNELER')}>I00 - Makineler</a></dt>
                </dl>
                <dl className="row">
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <dt className="col"><a href="#" onClick={handleInputChange('N00E - ENERJİ')}>N00E - Enerji</a></dt>
                </dl>
                <dl className="row">
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <dt className="col"><a href="#" onClick={handleInputChange('S00E - ÇEVRE')}>S00E - Çevre</a></dt>
                </dl>
                <dl className="row">
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <dt className="col"><a href="#" onClick={handleInputChange('S00S - SAĞLIK, TIBBİ CİHAZ')}>S00S - Sağlık, Tıbbi Cihaz</a></dt>
                </dl>
                <dl className="row">
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <dt className="col"><a href="#" onClick={handleInputChange('SERV - BİLGİ TOPLUMU HİZMETLERİ')}>SERV - Bilgi Toplumu Hizmetleri(98/48/EC)</a></dt>
                </dl>
                <dl className="row">
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <dt className="col"><a href="#" onClick={handleInputChange('T00T - ULAŞIM')}>T00T - Ulaşım</a></dt>
                </dl>
                <dl className="row">
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <dt className="col"><a href="#" onClick={handleInputChange('V00T - TELEKOMÜNİKASYON')}>V00T - Telekomünikasyon</a></dt>
                </dl>
                <dl className="row">
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <dt className="col"><a href="#" onClick={handleInputChange('X00M - DİĞER ÜRÜNLER')}>X00M - Diğer Ürünler</a></dt>
                </dl>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-9 mb-6 mb-lg-0 order-lg-1 p-0">
            <div className="card card-style-cnt">

              <div className="card-body">
                <p>
                  AB ile olan ticaretimizde malların serbest dolaşımının sağlanabilmesi ve
                  ihracatımızın teknik engellerle sekteye uğramaması amacıyla ülkemiz,
                  AB üyesi ülkelerin ulusal teknik düzenlemelerinin taslak aşamasında
                  görüşe sunulduğu teknik düzenlemeler bilgi sistemine dâhil olmuştur.
                  Söz konusu mekanizma kapsamında AB üyesi ülkelerin
                  gönderdiği mevzuat taslaklarına yönelik yorum
                  ve taleplerinizi bildirebilirsiniz.
                </p>
                <div className="row justify-content-center">
                  <div className="col-12 col-lg-6">
                    <div className="input-group mb-3">
                      <input type="text" className="form-control" placeholder="AB Bildirimi Ara" aria-describedby="basic-addon2" value={filtre} onChange={handleFilterChange} />
                      <div className="input-group-append">
                        <span className="input-group-text">
                          <img src={search} alt="" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                {abBildirimler()}
                <div className="mt-4 pageList">
                  <nav className="float-right" aria-label="Page navigation example">
                    {navigasyon()}
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withHeader(AbBildirimler, 'AB Bildirimleri');
