import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import './cards.scss';

const appserver = process.env.REACT_APP_BACKEND_URL;

//------------------------------
export const MainPageLinkCard = ({
  text, img, to, addClass,
}) => (
  <div className={`media ${addClass}`}>
    <div className="media-body">
      <Link to={to}>{text}</Link>
    </div>
    <div>
      <img src={img} className="ml-3" alt="..." />
    </div>
  </div>
);

MainPageLinkCard.propTypes = {
  text: PropTypes.string,
  img: PropTypes.string.isRequired,
  to: PropTypes.string,
  addClass: PropTypes.string,
};

MainPageLinkCard.defaultProps = {
  to: '#',
  text: 'text',
  addClass: '',
};

//------------------------------
export const FormCard = ({
  img, title, content, addClass, children,
}) => (
  <div className={`card ${addClass} card-style `}>
    <div className="BilgilerBox-card-body">
      <h5 className="BilgilerBox-card-title">
        <div className="d-flex align-items-center">
          <img src={img} alt="Bilgiye Ulaşın" />
          <p className="mb-0 ml-2">{title}</p>
        </div>
      </h5>
      <p className="BilgilerBox-card-text">{content}</p>
      {children}
    </div>
  </div>
);

FormCard.propTypes = {
  title: PropTypes.string,
  img: PropTypes.string.isRequired,
  children: PropTypes.element,
  content: PropTypes.string,
  addClass: PropTypes.string,
};

FormCard.defaultProps = {
  title: 'title',
  content: 'content',
  addClass: '',
  children: undefined,
};

//------------------------------
export const CountCard = ({ title, content, img }) => (
  <div id="guncelDurum" className="card">
    <div className="card-header">
      <img src={img} className="card-img-center" alt="..." />
    </div>
    <div className="card-body text-center">
      <h5 className="card-title">
        {title}
      </h5>
      <p className="card-text">{content}</p>
    </div>
  </div>
);

CountCard.propTypes = {
  title: PropTypes.string,
  img: PropTypes.string.isRequired,
  content: PropTypes.string,
};

CountCard.defaultProps = {
  title: 'title',
  content: 'content',
};
/* ----------- news card; bildirimlerCard ve DuyurularCar'ın aynı kod bloğu olan bölümleri ------ */
const NewsCard = ({
  title, img, children, addClass, to,
}) => (
  <div className={`card card-style ${addClass}`}>
    <div className="card-body pb-0">
      <h5 className="card-title">
        <div className="d-flex align-items-center">
          <img src={img} alt="icon" />
          <p className="mb-0 ml-2">
            {title}
          </p>
        </div>
      </h5>
      {children}
    </div>
    <div className="card-footer">
      <Link to={to} className="card-link text-primary float-right">
        Tümünü Gör
      </Link>
    </div>
  </div>
);

NewsCard.propTypes = {
  addClass: PropTypes.string,
  children: PropTypes.element,
  title: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  to: PropTypes.string,
};

NewsCard.defaultProps = {
  addClass: '',
  children: undefined,
  to: undefined,
};

export const BildirimlerCard = ({
  children, img, addClass, to, title,
}) => (
  <NewsCard img={img} to={to} title={title} addClass={`guncelDto ${addClass}`}>
    {children}
  </NewsCard>
);

BildirimlerCard.propTypes = {
  addClass: PropTypes.string,
  children: PropTypes.element,
  img: PropTypes.string,
  title: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

BildirimlerCard.defaultProps = {
  addClass: '',
  children: undefined,
  img: undefined,
};

export const DuyurularCard = ({
  children, img, addClass, to, title,
}) => (
  <NewsCard img={img} to={to} title={title} addClass={`duyurular ${addClass}`}>
    {children}
  </NewsCard>
);
DuyurularCard.propTypes = {
  addClass: PropTypes.string,
  children: PropTypes.element,
  img: PropTypes.string,
  title: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

DuyurularCard.defaultProps = {
  addClass: '',
  children: undefined,
  img: undefined,
};

//--------------------------------
export const FlagCard = ({
  item, img, addClass,
}) => (
  <div id="flagCard" className={`card h-100 ${addClass}`}>
    <img src={img} className="card-img-top" alt="..." />
    <div className="card-footer">
      <Link to={`/ulke/${item.link}`}>{item.ad}</Link>
    </div>
  </div>
);

FlagCard.propTypes = {
  img: PropTypes.string.isRequired,
  addClass: PropTypes.string,
  item: PropTypes.node.isRequired,
};

FlagCard.defaultProps = {
  addClass: '',
};

//------------------------------
export const CountryCard = ({
  index, item, addClass, children,
}) => (
  <div className={`col p-2 ${addClass}`} key={`bildirim${index}`}>
    <div className="dtoBildirimleriBox-card h-100">
      <div className="dtoBildirimleriBox-card-header">
        <img
          src={`../../images/bayraklar-kucuk/${item.ulke.id}.png`}
          alt={`${item.ulke.ad} bayrak`}
        />
        {item.ulke.ad}
      </div>
      <div className="dtoBildirimleriBox-card-body p-0">
        <h6 className="dtoBildirimleriBox-card-title">{item.ceviriBaslik}</h6>
        <hr />
        <div className="pl-2 pr-2">
          <dl className="row">
            <dt className="col">Kod:</dt>
            <dd className="col text-right">{item.kod}</dd>
          </dl>
          <dl className="row">
            <dt className="col">Özet İçerik:</dt>
            <dd className="col text-right">
              {item.enBelgeVar && (
              <a href={`${appserver}/download/${item.id}/EN`} className="card-link">
                EN
              </a>
              )}
              {item.frBelgeVar && (
              <a href={`${appserver}/download/${item.id}/FR`} className="card-link">
                FR
              </a>
              )}
              {item.esBelgeVar && (
              <a href={`${appserver}/download/${item.id}/ES`} className="card-link">
                ES
              </a>
              )}
            </dd>
          </dl>
          <dl className="row">
            <dt className="col">Eklenme Tarihi:</dt>
            <dd className="col text-right">{item.yayinlanmaTarihi}</dd>
          </dl>
          <dl className="row">
            <dt className="col">Son Yorum Tarihi:</dt>
            <dd className="col text-right">
              {item.sonYorumTarihi ? item.sonYorumTarihi : 'Belirtilmemiş'}
            </dd>
          </dl>
        </div>
      </div>
      {children}
    </div>
  </div>
);

CountryCard.propTypes = {
  index: PropTypes.number.isRequired,
  addClass: PropTypes.string,
  item: PropTypes.node.isRequired,
  children: PropTypes.element,
};

CountryCard.defaultProps = {
  addClass: '',
  children: undefined,
};
