import React from 'react';
import './navbar.scss';

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { DropdownSubmenu } from 'react-bootstrap-submenu';
import PropTypes from 'prop-types';

export const NavbarSb = ({ img, button }) => (
  <div id="header-wrap" className="position-absolute w-100 z-index-1">
    <div className="container">
      <Navbar collapseOnSelect expand="lg" variant="light" sticky="top">
        <Navbar.Brand href="/">
          <img src={img} height="58" className="d-inline-block align-top" alt="TTE Logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Link className="nav-item" href="/bildirimler">
              DTÖ Bildirimleri
            </Nav.Link>
            <NavDropdown title="Bilgi Merkezi" id="collasible-nav-dropdown">
              <NavDropdown.Item href="/teknik-engel-nedir" className="dropdown-item">
                Teknik Engel Nedir?
              </NavDropdown.Item>
              <NavDropdown.Item href="/teknik-engel-asma-anahtari" className="dropdown-item">
                Teknik Engel Aşma Anahtarı
              </NavDropdown.Item>
              <NavDropdown.Item href="/dunya" className="dropdown-item">
                Ülkeler İtibariyle Teknik Engeller
              </NavDropdown.Item>
              <NavDropdown.Item href="/teknikEngel" className="dropdown-item">
                Karşılaştığınız Teknik Engelleri Bildirin
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Yararlı Linkler" id="collasible-nav-dropdown">
              <DropdownSubmenu title="Ticaret Bakanlığı" id="collasible-nav-dropdown">
                <NavDropdown.Item target="_blank" href="https://www.ticaret.gov.tr/">
                  Ticaret Bakanlığı Ana Sayfa
                </NavDropdown.Item>
                <NavDropdown.Item target="_blank" href="https://www.urunguvenligi.gov.tr/">
                  Ürün Güvenliği Portalı
                </NavDropdown.Item>
                <NavDropdown.Item
                  target="_blank"
                  href="https://ec.europa.eu/growth/tools-databases/nando/index.cfm?fuseaction=country.notifiedbody&cou_id=792"
                >
                  Onaylanmış Kuruluşlar
                </NavDropdown.Item>
                <NavDropdown.Item target="_blank" href="https://www.kolayihracat.gov.tr/">
                  Kolay İhracat
                </NavDropdown.Item>
              </DropdownSubmenu>
              <DropdownSubmenu title="Dünya Ticaret Örgütü" id="collasible-nav-dropdown">
                <NavDropdown.Item
                  target="_blank"
                  href="https://www.wto.org/english/tratop_e/tbt_e/tbt_e.htm"
                >
                  Ticarette Teknik Engeller
                </NavDropdown.Item>
                <NavDropdown.Item target="_blank" href="https://www.epingalert.org/en">
                  E-ping
                </NavDropdown.Item>
              </DropdownSubmenu>
              <DropdownSubmenu title="Avrupa Birliği" id="collasible-nav-dropdown">
                <NavDropdown.Item
                  target="_blank"
                  href="https://ec.europa.eu/growth/tools-databases/tbt/en/"
                >
                  Ticarette Teknik Engeller
                </NavDropdown.Item>
                <NavDropdown.Item
                  target="_blank"
                  href="https://ec.europa.eu/growth/tools-databases/tris/en/"
                >
                  98/34 Bildirim Sistemi
                </NavDropdown.Item>
              </DropdownSubmenu>
              <DropdownSubmenu title="Helal Akreditasyon Kurumu" id="collasible-nav-dropdown">
                <NavDropdown.Item
                  target="_blank"
                  href="https://www.hak.gov.tr/dis-iliskiler/ulke-bazli-helal-duzenlemeler"
                >
                  Ülkelerin Helal Düzenlemeleri
                </NavDropdown.Item>
              </DropdownSubmenu>
              <DropdownSubmenu title="Türk Standartları Enstitüsü" id="collasible-nav-dropdown">
                <NavDropdown.Item
                  target="_blank"
                  href="https://www.tse.org.tr/IcerikDetay?ID=2945&ParentID=9280"
                >
                  İhracata Yönelik Uygunluk Değerlendirmesi Hizmetleri
                </NavDropdown.Item>
              </DropdownSubmenu>
              <DropdownSubmenu title="Türk Akreditasyon Kurumu" id="collasible-nav-dropdown">
                <NavDropdown.Item target="_blank" href=" https://www.turkak.org.tr/">
                  Ana Sayfa
                </NavDropdown.Item>
              </DropdownSubmenu>
            </NavDropdown>
            <Nav.Link className="nav-item" href="iletisim">
              İletişim
            </Nav.Link>
          </Nav>
          <Nav>{button}</Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  </div>
);

NavbarSb.propTypes = {
  img: PropTypes.string.isRequired,
  button: PropTypes.element,
};

NavbarSb.defaultProps = {
  button: undefined,
};

export default NavbarSb;
