import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import withHeader from './withHeader';
import LoginModal from '../components/Header/Modal';
import topImage from '../images/img_footer.png';
import bannerImage from '../images/img_banner_large_02.jpg';
import {
  getAnasayfaBildirimleri,
  getAnasayfaDuyurulari,
  getFirmaSayisi,
  getDtoBildirimSayisi,
  getAbBildirimSayisi,
  getIcerik,
} from '../service/service';
import ulkelerEngel from '../images/img_ulkelerEngel_02.jpg';
import bilgiyeUlas from '../icons/icn_bilgiyeUlas.svg';
import bildirimlerEposta from '../icons/icn_bildirimlerEposta.svg';
import arrow from '../icons/icn_arrow.svg';
import duyuruIcon from '../icons/icn_duyurular.svg';
import dtoBildirim from '../icons/icn_dtoBildirim.svg';
import ihracat from '../icons/icn_ihracat_01.png';
import ihracat2 from '../icons/icn_ihracat_02.png';
import ihracat3 from '../icons/icn_ihracat_03.png';
import dtoIcon from '../icons/icn_dto.svg';
import abIcon from '../icons/icn_ab.svg';
import userIcon from '../icons/icn_users.svg';
import {
  BildirimlerCard, CountCard, DuyurularCard, FormCard, MainPageLinkCard,
} from '../stories/Cards/Cards';
import { Button } from '../stories/Buttons/Button';

function Home(props) {
  const [bildirimler, setBildirimler] = useState([]);
  const [duyurular, setDuyurular] = useState([]);
  const [firmaSayisi, setFirmaSayisi] = useState(0);
  const [dtoBildirimSayisi, setDtoBildirimSayisi] = useState(0);
  const [abBildirimSayisi, setAbBildirimSayisi] = useState(0);
  const [menu1Icerik, setMenu1Icerik] = React.useState({});
  const [menu2Icerik, setMenu2Icerik] = React.useState({});
  const [menu3Icerik, setMenu3Icerik] = React.useState({});
  const [menu4Icerik, setMenu4Icerik] = React.useState({});
  const [isGirisModalOpen, setGirisModalOpen] = useState(false);
  const { handleLogin, user } = props;

  // works like componentDidMount
  useEffect(() => getAnasayfaBildirimleri()
    .then((res) => {
      if (res && res.data) {
        setBildirimler(res.data);
      }
    })
    .then(() => {
      getAnasayfaDuyurulari().then((res) => {
        if (res && res.data) {
          setDuyurular(res.data);
        }
      });
    })
    .then(() => {
      getFirmaSayisi().then((res) => {
        if (res && res.data) {
          setFirmaSayisi(res.data);
        }
      });
    })
    .then(() => {
      getDtoBildirimSayisi().then((res) => {
        if (res && res.data) {
          setDtoBildirimSayisi(res.data);
        }
      });
    })
    .then(() => {
      getAbBildirimSayisi().then((res) => {
        if (res && res.data) {
          setAbBildirimSayisi(res.data);
        }
      });
    })
    .then(() => {
      getIcerik('dto-bildirimleri').then((res) => {
        if (res && res.data) {
          setMenu1Icerik(res.data);
        }
      });
    })
    .then(() => {
      getIcerik('ulkeler-itibariyle-teknik-engeller').then((res) => {
        if (res && res.data) {
          setMenu2Icerik(res.data);
        }
      });
    })
    .then(() => {
      getIcerik('bilgiye-ulasin').then((res) => {
        if (res && res.data) {
          setMenu3Icerik(res.data);
        }
      });
    })
    .then(() => {
      getIcerik('bildirimler-epostaniza-gelsin').then((res) => {
        if (res && res.data) {
          setMenu4Icerik(res.data);
        }
      });
    }),
  []);

  const handleOpenLoginHref = () => (event) => {
    event.preventDefault(true);
    setGirisModalOpen(true);
  };
  const handleCloseLoginModal = () => {
    setGirisModalOpen(false);
  };

  const anasayfaBildirimleri = () => {
    if (bildirimler.length > 0) {
      return (
        <ul className="list-group list-group-flush mb-0">
          {bildirimler.slice(0, 5).map((item) => (
            <li className="list-group-item" key="bildirim {index}">
              <Link to={`/bildirimler/${item.id}`}>
                <div>
                  <img
                    src={`../images/bayraklar-kucuk/${item.ulke.id}.png`}
                    alt={`${item.ulke.ad} Bayrak`}
                  />
                  <span className="ml-2">{item.ulke.ad}</span>
                  {' '}
                </div>
                <div>{item.ceviriBaslik}</div>
              </Link>
            </li>
          ))}
        </ul>
      );
    }
    return (
      <div className="alert alert-primary" role="alert">
        Güncel Bildirim bulunmamaktadır!
      </div>
    );
  };

  const anasayfaDuyurulari = () => {
    if (duyurular.length > 0) {
      return (
        <ul className="list-group list-group-flush mb-0">
          {duyurular.slice(0, 5).map((item) => (
            <li className="list-group-item" key="duyuru {index}">
              <Link to={`/duyurular/${item.id}`}>
                <div>
                  <img src={arrow} alt="liste başı" />
                  <span className="ml-2">{item.baslik}</span>
                </div>
                <div className="date">{item.tarihString}</div>
              </Link>
            </li>
          ))}
        </ul>
      );
    }
    return (
      <div className="alert alert-primary" role="alert">
        Güncel Bildirim bulunmamaktadır!
      </div>
    );
  };

  return (
    <div className="page-wrapper">
      <Modal show={isGirisModalOpen} onHide={handleCloseLoginModal}>
        <Modal.Body>
          <LoginModal
            closeLoginModal={handleCloseLoginModal}
            handleLogin={handleLogin}
          />
        </Modal.Body>
      </Modal>
      <div
        className="custom-pt-1 bg-primary position-relative parallaxie"
        data-bg-img={topImage}
        src={topImage}
      >
        <div className="row align-items-center">
          <div className="col-12 col-lg-5 ml-auto mb-8 order-lg-1 p-0">
            {/* <!-- Image --> */}
            <div className="divider divider-side transform-flip-y bg-banner d-none d-lg-block" />
            <img className="flex-fill" src={bannerImage} alt="banner resmi" />
          </div>
          <div className="col-12 col-lg-7">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col col-md-10 col-xl-9">
                  {/* <!-- Heading --> */}
                  <h1 className="text-white mb-3">{menu1Icerik.baslik}</h1>
                  {/* <!-- Text --> */}
                  <p className="lead">{menu1Icerik.icerikButun}</p>
                  <Button
                    secondary
                    addClass="mr-1"
                    to="/bildirimler"
                    text="Devamını Oku"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- / .container --> */}
        <div
          className="shape-1 bottom"
          style={{ height: '150px', overflow: 'hidden' }}
        >
          <svg
            viewBox="0 0 500 150"
            preserveAspectRatio="none"
            style={{ height: '100%', width: '100%' }}
          >
            <path
              d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
              style={{ stroke: 'none', fill: '#fff' }}
            />
          </svg>
        </div>
      </div>
      <div className="page-content mt-0">
        {/* <!-- Row-1 alanı start -->   */}
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-8 col-xl-8 ulkelerEngelBox">
              <div className="card mb-3">
                <div className="row no-gutters">
                  <div className="col-md-4">
                    <img src={ulkelerEngel} className="card-img" alt="..." />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h5 className="card-title" style={{ fontSize: '1.9rem' }}>
                        {menu2Icerik.baslik}
                      </h5>
                      <p className="card-text mt-3">
                        {menu2Icerik.icerikButun}
                      </p>
                      <Button
                        addClass="mt-2"
                        to="/dunya"
                        text="Devamını Oku"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-4 col-xl-4 BilgilerBox">
              <FormCard
                img={bilgiyeUlas}
                title={menu3Icerik.baslik}
                content={menu3Icerik.icerikButun}
              >
                {user && user.sifreId && (
                <Link to="/mevzuatTalep" className="card-link text-primary">
                  Devam
                </Link>
                )}
                {(!user || !user.sifreId) && (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a
                  href="#"
                  onClick={handleOpenLoginHref()}
                  className="card-link text-primary"
                >
                  Devam
                </a>
                )}
              </FormCard>

              <FormCard
                img={bildirimlerEposta}
                title={menu4Icerik.baslik}
                content={menu4Icerik.icerikButun}
                addClass="mt-3"
              >
                {user && user.sifreId && (
                <Link to="/firmaBilgileri" className="card-link text-primary">
                  Devam
                </Link>
                )}
                {(!user || !user.sifreId) && (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a
                  href="#"
                  onClick={handleOpenLoginHref()}
                  className="card-link text-primary"
                >
                  Devam
                </a>
                )}
              </FormCard>
            </div>
          </div>
        </div>
        {/* <!-- Row-1 alanı end -->  */}
        {/* <!-- Row-2 alanı start -->  */}

        <div className="bg-color">
          <div className="container">
            <div className="card-deck">

              <BildirimlerCard img={dtoBildirim} to="/bildirimler" title="Güncel DTÖ Bildirimleri">
                {anasayfaBildirimleri()}
              </BildirimlerCard>
              <DuyurularCard img={duyuruIcon} to="/duyurular" title="Duyurular">
                {anasayfaDuyurulari()}
              </DuyurularCard>

            </div>
          </div>
        </div>
        {/* <!-- Row-2 alanı end -->  */}
        {/* <!-- Row-3 alanı start -->  */}

        <div className="container ihracatBox">
          <div className="row">
            <div className="col-12 col-lg-4 col-xl-4">
              <MainPageLinkCard
                text="Teknik Engel Nedir?"
                img={ihracat}
                to="/teknik-engel-nedir"
              />
            </div>
            <div className="col-12 col-lg-4 col-xl-4">
              <MainPageLinkCard
                text="Teknik Engel Aşma Anahtarı!"
                img={ihracat2}
                to="/teknik-engel-asma-anahtari"
              />
            </div>
            <div className="col-12 col-lg-4 col-xl-4">
              <MainPageLinkCard
                text=" Karşılaştığınız Teknik Engelleri Bildirin"
                img={ihracat3}
                to="/teknikEngel"
              />
            </div>
          </div>
        </div>
        {/* <!-- Row-3 alanı end -->
      <!-- Row-4 alanı start -->  */}
        <div className="container">
          <div className="row justify-content-center cntTxt">
            <h2 className="">
              Ticarette Teknik Engeller İnternet Sitesi Güncel Durum
            </h2>
          </div>
        </div>
        <div className="container guncelDurum mb-4">
          <div className="row text-center align-items-center justify-content-between">
            <div className="col-lg-3 col-md-4">
              <CountCard
                img={dtoIcon}
                title="DTÖ Bildirimleri"
                content={dtoBildirimSayisi}
              />
            </div>

            <div className="col-lg-3 col-md-4">
              <CountCard
                img={abIcon}
                title="AB Bildirimleri"
                content={abBildirimSayisi}
              />
            </div>

            <div className="col-lg-3 col-md-4">
              <CountCard
                img={userIcon}
                title="Kullanıcılar"
                content={firmaSayisi}
              />
            </div>
          </div>
        </div>
        {/* <!-- Row-4 alanı end -->  */}
      </div>
    </div>
  );
}

Home.propTypes = {
  handleLogin: PropTypes.objectOf.isRequired,
  user: PropTypes.objectOf.isRequired,
};

export default withHeader(Home);
