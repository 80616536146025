import React from 'react';
import PropTypes from 'prop-types';

const HeaderSection = (props) => {
  const { sectionHeading } = props;

  if (sectionHeading) {
    return (
      <section style={{ marginTop: '5rem' }} className="pageTitle">
        <div className="pageTitle">
          <div className="container">
            <div className="row">
              <div className="col">
                {sectionHeading
                && <h4>{sectionHeading}</h4>}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
  return null;
};

HeaderSection.propTypes = {
  sectionHeading: PropTypes.objectOf.isRequired,
};

export default HeaderSection;
