import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import {
  getUlkeListesi, getSehirListesi, postProfil, postUyeliktenAyril, getFasilaBasliklar,
} from '../service/service';
import withHeader from './withHeader';
import UlkeListesi from '../components/UlkeListesi';
import SehirListesi from '../components/SehirListesi';

function FirmaBilgileri(props) {
  const [mail, setMail] = useState('');
  const [firmaAd, setFirmaAd] = useState();
  const [secilenUlke, setSecilenUlke] = useState(0);
  const [secilenSehir, setSecilenSehir] = useState(0);
  const [adSoyad, setAdSoyad] = useState('');
  const [mailAtilsin, setMailAtilsin] = useState(false);
  const [sifre, setSifre] = useState('');
  const [reSifre, setReSifre] = useState('');
  const [ilgilenilenUlkeler, setIlgilenenUlkeler] = useState([]);
  const [ulkeler, setUlkeler] = useState({});
  const [sehirler, setSehirler] = useState({});
  const [adSoyadProblem, setAdSoyadProblem] = useState(false);
  const [firmaAdProblem, setFirmaAdProblem] = useState(false);
  const [emailProblem, setEmailProblem] = useState(false);
  const [passwordProblem, setPasswordProblem] = useState(false);
  const [emailMessage, setEmailMessage] = useState('');
  const [fasilaBasliklar, setFasilaBasliklar] = useState([]);
  const [ilgilenilenFasilaBasliklar, setIlgilenilenFasilaBasliklar] = useState([]);
  const { user, alertVer, handleLogin } = props;
  useEffect(() => {
    if (user) {
      setMail(user.mail);
      setFirmaAd(user.firmaAd);
      setSecilenUlke(user.ulke && user.ulke.id);
      setSecilenSehir(user.sehir && user.sehir.id);
      setAdSoyad(user.adSoyad);
      setMailAtilsin(user.mailAtilsin);
      setIlgilenenUlkeler(user.ilgilenilenUlkeler);
      setIlgilenilenFasilaBasliklar(user.fasilaBasliklar);
    }
    return getUlkeListesi()
      .then((res) => {
        if (res && res.data) {
          setUlkeler(res.data);
        }
      })
      .then(
        getSehirListesi()
          .then((res) => {
            if (res && res.data) {
              setSehirler(res.data);
            }
          }),
      )
      .then(
        getFasilaBasliklar()
          .then((res) => {
            setFasilaBasliklar(res.data);
          }),
      );
  }, [user]);
  const handleInputChange = (fieldType) => (event) => {
    switch (fieldType) {
      case 'adSoyad':
        setAdSoyad(event.target.value);
        break;
      case 'ulke':
        setSecilenUlke(event.target.value);
        break;
      case 'sehir':
        setSecilenSehir(event.target.value);
        break;
      case 'mail':
        setMail(event.target.value);
        break;
      case 'firmaAd':
        setFirmaAd(event.target.value);
        break;
      case 'mailAtilsin':
        setMailAtilsin(event.target.checked);
        break;
      case 'sifre':
        setSifre(event.target.value);
        break;
      case 'reSifre':
        setReSifre(event.target.value);
        break;
      case 'ilgilenilenUlkelerEkle': {
        const eklenecek = ulkeler.filter((item) => item.id === parseInt(event.target.value, 10));
        const ilgilenilecekUlkeler = ilgilenilenUlkeler.concat(eklenecek);
        setIlgilenenUlkeler(ilgilenilecekUlkeler);
        break;
      }
      case 'ilgilenilenUlkelerCikart': {
        const yeniUlkeler = ilgilenilenUlkeler.filter((item) => item.id
        !== parseInt(event.target.value, 10));
        setIlgilenenUlkeler(yeniUlkeler);
        break;
      }
      case 'ilgilenilenUgCikart': {
        const yeniUg = ilgilenilenFasilaBasliklar.filter((item) => item.id
        !== parseInt(event.target.value, 10));
        setIlgilenilenFasilaBasliklar(yeniUg);
        break;
      }
      case 'ilgilenilenUgEkle': {
        const eklenecekUg = fasilaBasliklar.filter((item) => item.id
        === parseInt(event.target.value, 10));
        const ilgilenilecekUg = ilgilenilenFasilaBasliklar.concat(eklenecekUg);
        setIlgilenilenFasilaBasliklar(ilgilenilecekUg);
        break;
      }
      default:
        break;
    }
  };

  const isValidEmail = (val) => {
    const regEmail = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return (regEmail.test(val));
  };

  const handleSubmit = (event) => {
    event.preventDefault(true);
    if (!mail) {
      setEmailMessage('Eposta alanı boş olamaz.');
    } else if (!isValidEmail(mail)) {
      setEmailProblem(true);
      setEmailMessage('Lütfen eposta adresi giriniz.');
      return;
    }
    if (sifre || reSifre) {
      if (sifre.localeCompare(reSifre) !== 0) {
        setPasswordProblem(true);
      } else {
        setPasswordProblem(false);
      }
    } else {
      setPasswordProblem(false);
    }

    setAdSoyadProblem(!adSoyad);
    setEmailProblem(!mail);
    setFirmaAdProblem(!firmaAd);
    if (!mail || !firmaAd || !adSoyad || ((sifre || reSifre) && sifre !== reSifre)) {
      return;
    }
    const degisecekSifre = sifre || user.sifre;
    const sehirJson = sehirler.filter((item) => item.id === parseInt(secilenSehir, 10));
    const ulkeJson = ulkeler.filter((item) => item.id === parseInt(secilenUlke, 10));
    const kullanici = {
      firmaAd,
      adSoyad,
      mail,
      mailAtilsin,
      id: user.id,
      sehir: sehirJson[0],
      ulke: ulkeJson[0],
      sifre: degisecekSifre,
      ilgilenilenUlkeler,
      fasilaBasliklar: ilgilenilenFasilaBasliklar,
    };
    postProfil(kullanici)
      .then((res) => {
        if (res && res.msg && res.msg === 'İşlem Başarılı') {
          alertVer('Güncelleme başarılı', 'Profil güncelleme işleminiz başarılı bir şekilde gerçekleştirilmiştir.', 'success');
          const sistemKullanici = {
            ...props.user,
            firmaAd,
            adSoyad,
            mail,
            mailAtilsin,
            sehir: sehirJson[0],
            ulke: ulkeJson[0],
            sifre: degisecekSifre,
            ilgilenilenUlkeler,
            fasilaBasliklar: ilgilenilenFasilaBasliklar,
          };
          handleLogin(sistemKullanici);
        }
      })
      .catch((err) => {
        alertVer('Güncelleme başarısız', err, 'danger');
      });
  };

  const handleUyeliktenAyril = (event) => {
    event.preventDefault(true);
    postUyeliktenAyril(user);
  };
  return (
    <div className="page-content">
      {/* eslint-disable-next-line react/destructuring-assignment */}
      {props.continue && (!user || !user.sifreId) && <Redirect to="/" />}
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10">
            {/* <!-- 1. grup --> */}
            <h4 className="text-primary mb-4 mt-4">Kişisel Bilgiler</h4>
            <form className="row" method="post" action="#" noValidate>
              <div className="form-group col-md-6">
                <input
                  id="form_name"
                  type="text"
                  name="name"
                  className={`form-control 
                    ${adSoyadProblem && 'is-invalid'}`}
                  placeholder="Adınız Soyadınız"
                  value={adSoyad}
                  onChange={handleInputChange('adSoyad')}
                />
                {adSoyadProblem
                  && (
                  <div className="invalid-feedback">
                    Ad alanı boş olamaz.
                  </div>
                  )}
              </div>
              <div className="form-group col-md-6">
                <input
                  id="form_name"
                  type="text"
                  name="mail"
                  className={`form-control 
                    ${emailProblem && 'is-invalid'}`}
                  placeholder="Email"
                  onChange={handleInputChange('mail')}
                  value={mail}
                />
                {emailProblem
                    && (
                    <div className="invalid-feedback">
                      {emailMessage}
                    </div>
                    )}
              </div>
              <div className="form-group col-md-6">
                <input
                  id="form_name"
                  type="text"
                  name="firma"
                  className={`form-control 
                    ${firmaAdProblem && 'is-invalid'}`}
                  placeholder="Firma Adı"
                  onChange={handleInputChange('firmaAd')}
                  value={firmaAd}
                />
                {firmaAdProblem
                    && (
                    <div className="invalid-feedback">
                      Firma Adı boş olamaz.
                    </div>
                    )}
              </div>
              <div className="form-group col-md-6">
                <UlkeListesi secilenUlke={secilenUlke} handleInputChange={handleInputChange('ulke')} />
              </div>
              <div className="form-group col-md-6">
                <SehirListesi selected={secilenSehir} handleInputChange={handleInputChange('sehir')} />
              </div>
            </form>
            {/* <!-- 2. grup --> */}
            <h4 className="text-primary mb-4 mt-4">İlgilenilen Ürün Ve Ürün Grupları</h4>
            <p className="text-justify">
              Lütfen ilgilendiğiniz ve bildirimlerini almak istediğiniz ürün ve ürün gruplarını
              seçiniz.  Ürün grupları, fasıl (2’li) veya tarife (4’lü) Gümrük Tarife
              İstatistik Pozisyonu (GTİP) bazında seçim yapabilirsiniz.
            </p>
            <form className="row" method="post" action="#" noValidate>
              <div className="form-group col-md-6">
                <div>
                  Ürün ve Ürün Grupları
                  <select className="custom-select" size="10" onChange={handleInputChange('ilgilenilenUgEkle')}>
                    {fasilaBasliklar && fasilaBasliklar
                      .filter((item) => {
                        if (ilgilenilenFasilaBasliklar) {
                          return !ilgilenilenFasilaBasliklar
                            .map((item2) => item2.id).includes(item.id);
                        }
                        return '';
                      })
                      .map((item) => (
                        <option
                          key={item.id}
                          value={item.id}
                        >
                          {item.tamAdi}
                        </option>
                      ))}
                  </select>
                </div>
                {/* <SuperTreeview data={treeState} isDeletable={(node, depth) => { return false; }}
                  onUpdateCb={(updatedData) => {
                    console.log(updatedData);
                    setTreeState(updatedData)
                }}
                isExpandable={(node, depth) => { return true; }}
                className="custom-select"/> */}
              </div>
              <div className="form-group col-md-6">
                <div>Seçtiğim Ürün ve Ürün Grupları</div>
                <select className="custom-select" size="10" onChange={handleInputChange('ilgilenilenUgCikart')}>
                  {ilgilenilenFasilaBasliklar && ilgilenilenFasilaBasliklar.length > 0
                  && ilgilenilenFasilaBasliklar.map((item) => (
                    <option
                      key={item.id}
                      value={item.id}
                    >
                      {item.tamAdi}
                    </option>
                  ))}
                  {user && user.gtip2CodeList && user.gtip2CodeList
                    .map((item) => (<option key={item.id} value={item.id}>{item.tamAdi}</option>))}
                  {user && user.gtip4CodeList && user.gtip4CodeList
                    .map((item) => (<option key={item.id} value={item.id}>{item.tamAdi}</option>))}
                </select>
              </div>
            </form>
            {/* <!-- 3. grup --> */}
            <h4 className="text-primary mb-4 mt-4">İlgilenilen Ülkeler</h4>
            <form className="row" method="post" action="#" noValidate>
              <div className="form-group col-md-6">
                <div>Ülkeler</div>
                <UlkeListesi exclude={ilgilenilenUlkeler} size={10} handleInputChange={handleInputChange('ilgilenilenUlkelerEkle')} />
              </div>
              <div className="form-group col-md-6">
                <div>Seçtiğin Ülkeler</div>
                <UlkeListesi include={ilgilenilenUlkeler} size={10} handleInputChange={handleInputChange('ilgilenilenUlkelerCikart')} />
              </div>
            </form>

            <div className="form-group col-md-6">
              <input
                type="password"
                className={`form-control 
                      ${passwordProblem && 'is-invalid'}`}
                placeholder="Şifre"
                onChange={handleInputChange('sifre')}
              />
              {passwordProblem
                && (
                <div className="invalid-feedback">
                  Şifreler uyuşmuyor.
                </div>
                )}
            </div>
            <div className="form-group col-md-6">
              <input
                type="password"
                className={`form-control 
                      ${passwordProblem && 'is-invalid'}`}
                placeholder="Şifre Tekrar"
                onChange={handleInputChange('reSifre')}
              />
              {passwordProblem
                && (
                <div className="invalid-feedback">
                  Şifreler uyuşmuyor.
                </div>
                )}
            </div>
            <div className="form-group col-md-12">
              <div className="form-check">
                <label className="form-check-label" htmlFor="defaultCheck1">
                  Bildirimleri Mail Olarak Almak İstiyorum
                  <input className="form-check-input" type="checkbox" checked={mailAtilsin} id="defaultCheck1" onChange={handleInputChange('mailAtilsin')} />
                </label>
              </div>
            </div>
            <div className="col-md-12 text-right mt-4 mb-4">
              <button type="button" className="btn btn-primary mr-2" onClick={handleUyeliktenAyril}><span>Üyelikten Ayrıl</span></button>
              <button type="button" className="btn btn-primary" onClick={handleSubmit}><span> Güncelle</span></button>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}

FirmaBilgileri.propTypes = {
  user: PropTypes.objectOf.isRequired,
  alertVer: PropTypes.objectOf.isRequired,
  handleLogin: PropTypes.objectOf.isRequired,
  continue: PropTypes.objectOf.isRequired,
};

export default withHeader(FirmaBilgileri, 'Firma Bilgileri');
