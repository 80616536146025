import React from 'react';
import './form.scss';
import PropTypes from 'prop-types';

export const Input = ({
  addClass, check, children, ...props
}) => {
  const {
    onChange, value, id, name, type, placeholder,
  } = props;
  if (children) {
    console.log(`type is: ${type}`);
    return (
      <div className={` ${addClass}`}>
        <input placeholder={placeholder} type={type} id={id} name={name} onChange={onChange} value={value} className={`form-control ${check}`} />
        {children}
      </div>
    );
  } return <input placeholder={placeholder} type={type} id={id} name={name} onChange={onChange} value={value} className={`form-control ${addClass} ${check}`} />;
};

Input.propTypes = {
  addClass: PropTypes.string,
  check: PropTypes.func,
  children: PropTypes.element,
  onChange: PropTypes.func,
  value: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
};

Input.defaultProps = {
  addClass: '',
  check: undefined,
  children: undefined,
  onChange: undefined,
  value: undefined,
  id: undefined,
  name: undefined,
  placeholder: '',
  type: 'text',
};

export const Textarea = ({
  addClass, check, children, ...props
}) => {
  const {
    onChange, value, id, name, rows, placeholder,
  } = props;
  return (
    <div className={`${addClass}`}>
      <textarea placeholder={placeholder} rows={rows} id={id} name={name} onChange={onChange} value={value} className={`form-control ${check}`} />
      {children}
    </div>
  );
};

Textarea.propTypes = {
  addClass: PropTypes.string,
  check: PropTypes.func,
  children: PropTypes.element,
  onChange: PropTypes.func,
  value: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  rows: PropTypes.string,
  placeholder: PropTypes.string,
};

Textarea.defaultProps = {
  addClass: '',
  check: undefined,
  children: undefined,
  onChange: undefined,
  value: undefined,
  id: undefined,
  name: undefined,
  rows: '1',
  placeholder: '',
};
