import React from 'react';
import PropTypes from 'prop-types';

const AnketModal = (props) => {
  const { closeLoginModal } = props;

  const handleCloseLogin = () => (event) => {
    event.preventDefault(true);
    closeLoginModal();
  };

  const highlight = {
    color: 'red',
    fontWeight: 'bold',
    fontSize: '16px',
  };

  return (
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="loginLabel">
          Anket Katılım
        </h5>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={handleCloseLogin()}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body p-3">
        <form id="login-form" method="post" noValidate>
          <div className="form-group">
            İhraç pazarlarında karşılaşılan
            ticarette teknik engellerin tespiti ve  çözümüne
            yönelik çalışmalarda faydalanılacak anketimize katılmak için lütfen&nbsp;
            <a href="/anket" style={highlight}>tıklayınız.</a>
          </div>
        </form>
      </div>
    </div>
  );
};

AnketModal.propTypes = {
  closeLoginModal: PropTypes.objectOf.isRequired,
};

export default AnketModal;
