import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { login } from '../../../service/service';
import { Button } from '../../../stories/Buttons/Button';
import { Input } from '../../../stories/Form/Form';

const LoginModal = (props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailProblem, setEmailProblem] = useState(false);
  const [passwordProblem, setPasswordProblem] = useState(false);
  const [emailMessage, setEmailMessage] = useState('');
  const { closeLoginModal, changeModalState, handleLogin } = props;

  const handleCloseLogin = () => (event) => {
    event.preventDefault(true);
    closeLoginModal();
  };
  const handleModalChange = (newState) => (event) => {
    event.preventDefault(true);
    changeModalState(newState);
  };
  const isValiEmail = (val) => {
    const regEmail = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regEmail.test(val);
  };

  const handleLoginLocal = (event) => {
    event.preventDefault(true);
    if (!email) {
      setEmailMessage('Kullanıcı adı boş olamaz.');
    } else if (!isValiEmail(email)) {
      setEmailProblem(true);
      setEmailMessage('Lütfen eposta adresi giriniz.');
      return;
    }
    setEmailProblem(!email);
    setPasswordProblem(!password);
    if (!email || !password) {
      return;
    }
    login(email, password)
      .then((res) => {
        if (res && res.data && res.data.sifreId) {
          handleLogin(res.data);
          closeLoginModal();
        } else {
          setEmailProblem(true);
          setEmailMessage('Kullanıcı bulunamadı.');
        }
      })
      .catch(() => {
        setEmailMessage('Bağlantıda bir sorun oluştu.');
        setEmailProblem(true);
      });
  };

  const handleInputChange = (fieldType) => (event) => {
    switch (fieldType) {
      case 'email':
        setEmail(event.target.value);
        break;
      case 'password':
        setPassword(event.target.value);
        break;
      default:
        break;
    }
  };
  return (
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="loginLabel">
          Giriş Yap
        </h5>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={handleCloseLogin()}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body p-3">
        <form id="login-form" method="post" noValidate>
          <div className="form-group">
            <Input
              id="form_name"
              type="text"
              name="name"
              check={` ${emailProblem && 'is-invalid'}`}
              placeholder="E-mail"
              onChange={handleInputChange('email')}
            >
              {emailProblem && (
                <div className="invalid-feedback">{emailMessage}</div>
              )}
            </Input>
          </div>

          <div className="form-group">
            <Input
              id="form_password"
              type="password"
              name="password"
              className="form-control is-invalid"
              placeholder="Şifreniz"
              check={`${passwordProblem && 'is-invalid'}`}
              onChange={handleInputChange('password')}
            >
              {passwordProblem && (
                <div className="invalid-feedback">Şifre boş olamaz.</div>
              )}
            </Input>
          </div>
          <div className="form-group mt-4 mb-4">
            <div className="remember-checkbox d-flex align-items-center justify-content-between">
              <div className="checkbox">
                <label htmlFor="check2">
                  Beni Hatırla
                  <input
                    type="checkbox"
                    id="check2"
                    name="check2"
                    className="m-1"
                  />
                </label>
              </div>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                href="#"
                className="card-link"
                data-toggle="modal"
                data-target="#ForgotPassword"
                onClick={handleModalChange(2)}
              >
                Şifrenizi mi unuttunuz?
              </a>
            </div>
          </div>
          <Button
            block
            onClick={handleLoginLocal}
            text="Giriş Yap"
          />
        </form>
        <div className="d-flex align-items-center text-center justify-content-center mt-4">
          <span className="text-muted mr-1">Hesabın yok mu?</span>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a href="#" className="card-link" onClick={handleModalChange(1)}>
            Şimdi Üye Ol
          </a>
        </div>
      </div>
    </div>
  );
};

LoginModal.propTypes = {
  closeLoginModal: PropTypes.objectOf.isRequired,
  changeModalState: PropTypes.objectOf.isRequired,
  handleLogin: PropTypes.objectOf.isRequired,
};

export default LoginModal;
