import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import withHeader from './withHeader';
import LoginModal from '../components/Header/Modal';
import YorumModal from './YorumPanel';
import {
  getUlkeler,
  getGuncelBildirimler,
  getArsivBildirimler,
  getTamMetinIstek,
} from '../service/service';
import search from '../icons/icn_search.svg';
import ArsivModal from './ArsivModal';
import { DtoSideBox, DtoSideBoxList } from '../stories/SideBox/SideBox';
import { Input } from '../stories/Form/Form';

const appserver = process.env.REACT_APP_BACKEND_URL;

function Bildirimler(props) {
  const [ulkeler, setUlkeler] = useState([]);
  const [guncelBildirimler, setGuncelBildirimler] = useState([]);
  const [filtrelenmisBildirimler, setFiltrelenmisBildirimler] = useState([]);
  const [ulkeAd, setUlkeAd] = useState(null);
  const [sayfaSayisi, setSayfaSayisi] = useState(0);
  const [seciliSayfa, setSeciliSayfa] = useState(1);
  const [isArsivModalOpen, setArsivModalOpen] = React.useState(false);
  const [anahtarKelime, setAnahtarKelime] = React.useState('');
  const [bildirenUlke, setBildirenUlke] = React.useState(0);
  const [bildirimKodu, setBildirimKodu] = React.useState('');
  const [baslangicTarihi, setBaslangicTarihi] = React.useState('');
  const [bitisTarihi, setBitisTarihi] = React.useState('');
  const [isLoading, setLoading] = React.useState(true);
  const [isGirisModalOpen, setGirisModalOpen] = React.useState(false);
  const [filtre, setFiltre] = React.useState('');
  const [isYorumModalOpen, setYorumModalOpen] = useState(false);
  const [seciliBildirim, setSeciliBildirim] = useState({});
  const { bildirimId } = useParams();
  const { alertVer, user, handleLogin } = props;

  const handleOpenLoginHref = () => (event) => {
    event.preventDefault(true);
    setGirisModalOpen(true);
  };
  const handleCloseLoginModal = () => {
    setGirisModalOpen(false);
  };
  const handleOpenArsiv = () => {
    setArsivModalOpen(true);
  };

  const handleCloseArsivModal = () => {
    setArsivModalOpen(false);
  };

  useEffect(() => {
    setFiltre(bildirimId);
    return getUlkeler()
      .then((res) => {
        if (res && res.data) {
          setUlkeler(res.data);
        }
      })
      .then(() => {
        getGuncelBildirimler(ulkeAd).then((res) => {
          setLoading(false);
          if (res && res.data) {
            setGuncelBildirimler(res.data);
            const bildirimler = res.data.filter((item) => {
              if (bildirimId) {
                if (item.id === parseInt(bildirimId, 10)) {
                  return true;
                } if (
                  item
                  && item.ulke
                  && item.ulke.link
                  && item.ulke.link === bildirimId
                ) {
                  return true;
                }
                return false;
              }
              return true;
            });
            setFiltrelenmisBildirimler(bildirimler);
            setSayfaSayisi(Math.ceil(bildirimler.length / 6));
            setSeciliSayfa(1);
          }
        });
      })
      .catch(() => alertVer('Bildirimler Yüklenirken Bir Sorun Oluştu', '', 'danger'));
  }, [props, bildirimId, ulkeAd]);

  // eslint-disable-next-line no-shadow
  const handleUlkeSelect = (ulkeAd) => (event) => {
    event.preventDefault();
    setUlkeAd(ulkeAd);
    setSeciliSayfa(1);
    getGuncelBildirimler(ulkeAd).then((res) => {
      if (res && res.data) {
        setGuncelBildirimler(res.data);
        setFiltrelenmisBildirimler(res.data);
        setFiltre('');
        setSayfaSayisi(Math.ceil(res.data.length / 6));
      }
    });
  };

  const ulkePanel = () => {
    if (ulkeler.length > 0) {
      return (
        <DtoSideBoxList title="Ülkeler">
          {ulkeler.map((item) => (
            <dl className="row" key={`ulke${item.ulkeAd}`}>
              <dt className="col-sm-6">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="#" onClick={handleUlkeSelect(item.ulkeAd)}>
                  {item.ulkeAd}
                </a>
              </dt>
              <dd className="col-sm-6 text-right">{item.toplamKayit}</dd>
            </dl>
          ))}
        </DtoSideBoxList>
      );
    }
    return (
      <div className="alert alert-primary" role="alert">
        Ülke bulunmamaktadır!
      </div>
    );
  };

  const sayfaDegistir = (menuIndex) => () => {
    setSeciliSayfa(menuIndex);
  };

  const ilkBildirim = () => (seciliSayfa - 1) * 6;

  const yorumYap = (bildirim) => (event) => {
    event.preventDefault(true);
    setYorumModalOpen(true);
    setSeciliBildirim(bildirim);
  };
  const yorumPanel = (item) => {
    if (user && user.sifreId) {
      return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          href="#"
          className="btn btn-light float-right"
          onClick={yorumYap(item)}
        >
          Yorum Yap
        </a>
      );
    }
    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a
        href="#"
        className="btn btn-light float-right"
        onClick={handleOpenLoginHref()}
      >
        Yorum Yap
      </a>
    );
  };

  const tamMetinTalep = (bildirim) => (event) => {
    event.preventDefault(true);
    getTamMetinIstek(bildirim.id, user.sifreId)
      .then((res) => {
        if (res && res.data) {
          if (
            res.data
            === 'Daha önce bu bildirim için tam metin isteği talebinde bulundunuz. Tam metin yüklendiği zaman e-posta ile bilgilendirileceksiniz.'
          ) {
            alertVer(
              'Daha önce bu bildirim için tam metin isteği talebinde bulundunuz. Tam metin yüklendiği zaman e-posta ile bilgilendirileceksiniz.',
              '',
              'info',
            );
          } else if (
            res.data
            === 'Tam Metin İsteği Sistem Üzerinden İlgili Ülkeye Gönderilmiştir.'
          ) {
            alertVer(
              'Tam Metin İsteği Sistem Üzerinden İlgili Ülkeye Gönderilmiştir.',
              'Tam Metin İsteği Sistem Üzerinden İlgili Ülkeye Gönderilmiştir.',
              'info',
            );
          } else if (res.data === 'Tam Metin İsteği Sisteme İletilmiştir.') {
            alertVer(
              'Tam Metin İsteği Sisteme İletilmiştir.',
              'Tam metin isteğiniz sisteme kaydedilmiş olup tarafınıza en kısa sürede dönüş sağlanacaktır.',
              'success',
            );
          } else {
            alertVer('', res.data, 'info');
          }
        } else {
          alertVer(
            'Tam istek talebi gönderilemedi',
            'Bağlantıda sorun oluştu.',
            'danger',
          );
        }
      })
      .catch(() => {
        alertVer(
          'Tam istek talebi gönderilemedi',
          'Bağlantıda sorun oluştu.',
          'danger',
        );
      });
  };

  const tamMetinPanel = (item) => {
    if (item.tamBelgeVar) {
      return (
        <a
          href={`${appserver}/download/${item.id}/tamMetin`}
          className="btn btn-light"
        >
          Tam Metin
        </a>
      );
    } if (user && user.sifreId) {
      return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          href="#"
          className="btn btn-light"
          title="TAM METİN İSTE"
          onClick={tamMetinTalep(item)}
        >
          Tam Metin
        </a>
      );
    }
    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a
        href=""
        className="btn btn-light"
        title="TAM METİN İSTE"
        onClick={handleOpenLoginHref()}
      >
        Tam Metin
      </a>
    );
  };

  const bildirimFooterYazdir = (item) => (
    <div className="card-footer">
      {tamMetinPanel(item)}
      {yorumPanel(item)}
    </div>
  );

  const bildirimler = () => {
    if (filtrelenmisBildirimler && filtrelenmisBildirimler.length > 0) {
      return (
        <div className="row row-cols-1 row-cols-xl-3 row-cols-md-2 mt-2 ">
          {filtrelenmisBildirimler
            .slice(ilkBildirim(), ilkBildirim() + 6)
            .map((item) => (
              <div className="col p-2" key={`${item.id}`}>
                <div className="card h-100">
                  <div className="card-header">
                    <img
                      src={`../images/bayraklar-kucuk/${item.ulke.id}.png`}
                      alt={`${item.ulke.ad} bayrak`}
                    />
                    {item.ulke.ad}
                  </div>
                  <div className="card-body p-0">
                    <h6 className="card-title">{item.ceviriBaslik}</h6>
                    <hr />
                    <div className="pl-2 pr-2">
                      <dl className="row">
                        <dt className="col">Kod:</dt>
                        <dd className="col text-right">{item.kod}</dd>
                      </dl>
                      <dl className="row">
                        <dt className="col">Özet İçerik:</dt>
                        <dd className="col text-right">
                          {item.enBelgeVar && (
                          <a
                            href={`${appserver}/download/${item.id}/EN`}
                            className="card-link"
                          >
                            EN
                          </a>
                          )}
                          {item.frBelgeVar && (
                          <a
                            href={`${appserver}/download/${item.id}/FR`}
                            className="card-link"
                          >
                            FR
                          </a>
                          )}
                          {item.esBelgeVar && (
                          <a
                            href={`${appserver}/download/${item.id}/ES`}
                            className="card-link"
                          >
                            ES
                          </a>
                          )}
                        </dd>
                      </dl>
                      <dl className="row">
                        <dt className="col">Eklenme Tarihi:</dt>
                        <dd className="col text-right">
                          {item.yayinlanmaTarihi}
                        </dd>
                      </dl>
                      <dl className="row">
                        <dt className="col">Son Yorum Tarihi:</dt>
                        <dd className="col text-right">
                          {item.sonYorumTarihi
                            ? item.sonYorumTarihi
                            : 'Belirtilmemiş'}
                        </dd>
                      </dl>
                    </div>
                  </div>
                  {bildirimFooterYazdir(item)}
                </div>
              </div>
            ))}
        </div>
      );
    } if (isLoading) {
      return (
        <div className="alert alert-primary" role="alert">
          Bildirimler Yükleniyor!
        </div>
      );
    }
    return (
      <div className="alert alert-primary" role="alert">
        Güncel Bildirim bulunmamaktadır!
      </div>
    );
  };

  const sayfalar = () => {
    const sayfaTuslari = [];
    let baslangic = 0;
    let son = 0;
    if (sayfaSayisi < 6) {
      baslangic = 1;
      son = sayfaSayisi;
    } else if (seciliSayfa - 2 < 1) {
      baslangic = 1;
      son = seciliSayfa + 2 + (3 - seciliSayfa);
    } else if (seciliSayfa + 3 > sayfaSayisi) {
      baslangic = seciliSayfa - 2 - (3 - (sayfaSayisi - seciliSayfa));
      son = sayfaSayisi;
    } else {
      baslangic = seciliSayfa - 2 > 0 ? seciliSayfa - 2 : 1;
      son = seciliSayfa + 3 > sayfaSayisi ? sayfaSayisi : seciliSayfa + 2;
    }
    for (let i = baslangic; i < son + 1; i += 1) {
      sayfaTuslari.push(
        <li key={i} className={`page-item ${seciliSayfa === i && 'active'}`}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a href="#" className="page-link" onClick={sayfaDegistir(i)}>
            {i}
          </a>
        </li>,
      );
    }
    return sayfaTuslari;
  };

  const oncekiSayfa = () => {
    if (seciliSayfa > 1) {
      return (
        <li className="page-item">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            href="#"
            className="page-link"
            onClick={sayfaDegistir(seciliSayfa - 1)}
            aria-label="Previous"
          >
            <span aria-hidden="true">&laquo;</span>
          </a>
        </li>
      );
    }
    return null;
  };

  const sonrakiSayfa = () => {
    if (seciliSayfa < sayfaSayisi) {
      return (
        <li className="page-item">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            href="#"
            className="page-link"
            onClick={sayfaDegistir(seciliSayfa + 1)}
            aria-label="Next"
          >
            <span aria-hidden="true">&raquo;</span>
          </a>
        </li>
      );
    }
    return null;
  };

  const navigasyon = () => (
    <ul className="pagination">
      {oncekiSayfa()}
      {sayfalar()}
      {sonrakiSayfa()}
    </ul>
  );

  const bildirimAra = () => {
    setLoading(true);
    // const filtre = {};
    if (anahtarKelime && anahtarKelime.trim()) { filtre.anahtarKelime = anahtarKelime; }
    if (
      bildirimKodu
      && bildirimKodu.trim() !== ''
      && bildirimKodu.trim() !== 'G/TBT/N/'
    ) {
      filtre.bildirimKod = bildirimKodu.replace(/\//g, ',');
    }
    if (bildirenUlke && bildirenUlke > 0) filtre.ulkeId = bildirenUlke;
    if (baslangicTarihi) filtre.ilkDate = baslangicTarihi;
    if (bitisTarihi) filtre.sonDate = bitisTarihi;
    setAnahtarKelime('');
    setBildirenUlke(0);
    setBildirimKodu('');
    getArsivBildirimler(filtre)
      .then((res) => {
        if (res && res.data) {
          setLoading(false);
          setGuncelBildirimler(res.data);
          setFiltrelenmisBildirimler(res.data);
          setSayfaSayisi(Math.ceil(res.data.length / 6));
        }
      })
      .catch((err) => {
        alertVer(
          'Arama yapılırken hata ile karşılaşıldı.',
          err,
          'danger',
        );
      });
    handleCloseArsivModal();
  };

  const handleInputChange = (fieldType, value) => {
    switch (fieldType) {
      case 'anahtarKelime':
        setAnahtarKelime(value);
        break;
      case 'bildirenUlke':
        setBildirenUlke(value);
        break;
      case 'bildirimKodu':
        setBildirimKodu(value);
        break;
      case 'baslangicTarihi':
        setBaslangicTarihi(value);
        break;
      case 'bitisTarihi':
        setBitisTarihi(value);
        break;
      default:
        break;
    }
  };

  const handleFilterChange = (event) => {
    setFiltre(event.target.value);
    const gosterilecekBildirimler = guncelBildirimler.filter((item) => {
      const query = event.target.value.toLowerCase();
      return (
        (item.kod && item.kod.toLowerCase().search(query) >= 0)
        || item.id === parseInt(event.target.value, 10)
        || (item.baslik && item.baslik.toLowerCase().indexOf(query) >= 0)
        || (item.ceviriBaslik
          && item.ceviriBaslik.toLowerCase().search(query) > -1)
        || (item.ulke
          && item.ulke.ad
          && item.ulke.ad.toLowerCase().search(query) > -1)
      );
    });
    setFiltrelenmisBildirimler(gosterilecekBildirimler);
    setSayfaSayisi(Math.ceil(gosterilecekBildirimler.length / 6));
    setSeciliSayfa(1);
  };

  const handleCloseYorumModal = () => {
    setYorumModalOpen(false);
  };
  return (
    <div>
      <Modal show={isGirisModalOpen} onHide={handleCloseLoginModal}>
        <Modal.Body>
          <LoginModal
            closeLoginModal={handleCloseLoginModal}
            handleLogin={handleLogin}
          />
        </Modal.Body>
      </Modal>
      <Modal show={isYorumModalOpen} onHide={handleCloseYorumModal}>
        <Modal.Body>
          <YorumModal
            closeYorumModal={handleCloseYorumModal}
            sifreId={user && user.sifreId}
            bildirimId={seciliBildirim.id}
            alertVer={alertVer}
          />
        </Modal.Body>
      </Modal>
      {isArsivModalOpen && (
        <ArsivModal
          closeLoginModal={handleCloseArsivModal}
          bildirimAra={bildirimAra}
          handleInputChange={handleInputChange}
          bildirimKodu={bildirimKodu}
        />
      )}
      <div className="page-content">
        <div className="container pt-4">
          <div className="row">
            <div className="col-12 col-lg-3">
              <DtoSideBox addClass="mt-0" title="AB Bildirimleri">
                <Link to="/abBildirimler" className="card-link">
                  AB Bildirimleri için tıklayınız…
                </Link>
              </DtoSideBox>

              <DtoSideBox addClass="mb-3" title="Arşiv Bildirimleri">
                <p>
                  Yorum süresi sona eren bildirimlere veri tabanından
                  erişebilirsiniz.
                </p>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  href="#"
                  className="card-link"
                  role="button"
                  data-toggle="modal"
                  data-target="#dtoArsiv"
                  onClick={handleOpenArsiv}
                >
                  Arşiv
                </a>
              </DtoSideBox>

              {ulkePanel()}
            </div>

            <div className="col-12 col-lg-9 mb-6 mb-lg-0 order-lg-1 p-0">
              <div className="card card-style-cnt">
                <div className="card-body">
                  <p>
                    DTÖ bildirimleri ülkelerin mevzuat ve uygulamalarında
                    şeffaflığı sağlamak suretiyle teknik engellerin önlenmesine
                    katkı sağlamaktadır. Bildirimler aracılığıyla diğer
                    ülkelerin ihracatınızı olumsuz etkileyebilecek
                    düzenlemelerini taslak aşamasında öğrenip yorum yapabilir ve
                    değiştirilmesini sağlayabilirsiniz. DTÖ üyesi ülkelerin
                    yorum süresi devam eden bildirimleri aşağıda yer almaktadır.
                  </p>
                  <div className="row justify-content-center">
                    <div className="col-12 col-lg-6">
                      <div className="input-group mb-3">
                        <Input
                          type="text"
                          placeholder="DTÖ Bildirimi Ara"
                          aria-describedby="basic-addon2"
                          onChange={handleFilterChange}
                          value={filtre}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">
                            <img src={search} alt="arama ikonu" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="dtoBildirimleriBox">{bildirimler()}</div>
                  <div className="mt-4 pageList">
                    <nav
                      className="float-right"
                      aria-label="Page navigation example"
                    >
                      {navigasyon()}
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Bildirimler.propTypes = {
  alertVer: PropTypes.objectOf.isRequired,
  user: PropTypes.objectOf.isRequired,
  handleLogin: PropTypes.objectOf.isRequired,
};

export default withHeader(Bildirimler, 'DTÖ Bildirimleri');
