import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { getUlkeDetay } from '../service/service';
import withHeader from './withHeader';
import { DtoSideBox, DtoSideBoxList } from '../stories/SideBox/SideBox';

function Ulke(props) {
  const { ulkeAd } = useParams();
  const [ulke, setUlke] = useState({});
  const [aktifMenu, setAktifMenu] = React.useState(1);
  const { alertVer } = props;

  useEffect(() => getUlkeDetay(ulkeAd)
    .then((res) => {
      if (res && res.data) {
        setUlke(res.data);
      }
    })
    .catch((err) => alertVer('Bilgiler yüklenirken hata ile karşılaşıldı', err, 'danger')), [props, ulkeAd]);

  const handleAktifMenuClick = (menuIndex) => () => {
    setAktifMenu(menuIndex);
  };

  const icerik = () => {
    switch (aktifMenu) {
      case 1:
        return ulke.yetkiliKuruluslarButun;
      case 2:
        return ulke.ihracatBelgeleriButun;
      case 3:
        return ulke.helalBelgeleriButun;
      case 4:
        return ulke.urunGuvenligiDenetimiButun;
      case 6:
        return ulke.musavirlikGorusButun;
      default:
        return '';
    }
  };
  const kitaAdi = () => {
    switch (ulke.ulke && ulke.ulke.kita) {
      case 'NA':
        return 'Kuzey Amerika';
      case 'EU':
        return 'Avrupa';
      case 'AF':
        return 'Afrika';
      case 'AS':
        return 'Asya';
      case 'AU':
        return 'Avustralya';
      case 'SA':
        return 'Güney Amerika';
      default:
        return '';
    }
  };
  return (
    <div>
      <div className="page-content">
        <div className="container pt-4">
          <div className="row">
            <div className="col-lg-4">
              <div className="list-group cardSideBox">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="#" onClick={handleAktifMenuClick(1)} className={`list-group-item list-group-item-action ${aktifMenu === 1 && 'active'}`}>
                  Yetkili Kuruluşlar ve Mevzuat
                </a>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="#" onClick={handleAktifMenuClick(2)} className={`list-group-item list-group-item-action ${aktifMenu === 2 && 'active'}`}>İhracatta Talep Edilen Zorunlu Belgeler</a>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="#" onClick={handleAktifMenuClick(3)} className={`list-group-item list-group-item-action ${aktifMenu === 3 && 'active'}`}>Helal Belgelendirmesi</a>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="#" onClick={handleAktifMenuClick(4)} className={`list-group-item list-group-item-action ${aktifMenu === 4 && 'active'}`}>Ürün Güvenliği Denetimleri</a>
                {ulke && <Link to={ulke.ulke && `/bildirimler/${ulke.ulke.link}`} className="list-group-item list-group-item-action">Güncel DTÖ Bildirimleri</Link>}
                {!ulke && <div className="list-group-item list-group-item-action">Güncel DTÖ Bildirimleri</div>}
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="#" onClick={handleAktifMenuClick(6)} className={`list-group-item list-group-item-action ${aktifMenu === 6 && 'active'}`}>Müşavirlik Görüş ve Önerileri</a>
                <a className="list-group-item list-group-item-action" target="_blank" href={`${ulke.musavirlikLink}`} rel="noopener noreferrer">Müşavire Danışın</a>
              </div>

              <DtoSideBoxList title="Ülke Bilgileri">
                <dl className="row">
                  <dt className="col-sm-6">GSYİH (Milyon $):</dt>
                  <dd className="col-sm-6 text-right">{ulke.gsyih}</dd>
                </dl>
                <dl className="row">
                  <dt className="col-sm-6">KB GSYİH (Milyon $):</dt>
                  <dd className="col-sm-6 text-right">{ulke.kbgsyih}</dd>
                </dl>
                <dl className="row">
                  <dt className="col-sm-6">Büyüme Oranı(%):</dt>
                  <dd className="col-sm-6 text-right">{ulke.buyumeOrani}</dd>
                </dl>
                <dl className="row">
                  <dt className="col-sm-6">Nüfus:</dt>
                  <dd className="col-sm-6 text-right">
                    {ulke.nufus}
                    {' '}
                  </dd>
                </dl>
                <dl className="row">
                  <dt className="col-sm-6">Yüzölçümü (km):</dt>
                  <dd className="col-sm-6 text-right">{ulke.yuzolcumu}</dd>
                </dl>
                <dl className="row">
                  <dt className="col-sm-6">Başkent:</dt>
                  <dd className="col-sm-6 text-right">{ulke.baskent}</dd>
                </dl>
              </DtoSideBoxList>
              <DtoSideBox title="Temsilciliklerimiz">
                <a className="card-link" target="_blank" href={`${ulke.musavirlikLink}`} rel="noopener noreferrer">
                  Müşavirlik Sitesine Gitmek için Tıklayınız..
                </a>
              </DtoSideBox>
            </div>
            <div className="col-lg-8 p-0 ">
              <div className="card card-style-cnt">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">Anasayfa</Link></li>
                    {ulke && <li className="breadcrumb-item"><Link to={`/dunya/${ulke.ulke && ulke.ulke.kita}`}>{kitaAdi()}</Link></li>}
                    {ulke && <li className="breadcrumb-item active" aria-current="page">{ulke.ulke && ulke.ulke.ad}</li>}
                  </ol>
                </nav>
                <div className="card-body">
                  {/* eslint-disable-next-line react/no-danger */}
                  <div dangerouslySetInnerHTML={{
                    __html: icerik(),
                  }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Ulke.propTypes = {
  alertVer: PropTypes.objectOf.isRequired,
};

export default withHeader(Ulke, 'Ülkeler İtibariyle Teknik Engeller');
