import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Header from '../components/Header';
import Footer from '../components/Footer';
import SuccessModal from '../components/Header/Modal/SuccessModal';

function withHeader(WrappedComponent, sectionHeading) {
  class HeaderWithUserData extends Component {
    constructor(props) {
      super(props);
      this.state = {
        user: props.user,
        anketShow: false,
        alertShow: false,
        alertType: '',
        alertBaslik: '',
        alertMesaj: '',
        continueWithUser: false,
      };
    }

    async componentDidMount() {
      const currentUser = await localStorage.getItem('user');
      this.setState({
        user: JSON.parse(currentUser),
        continueWithUser: true,
      });
    }

    handleLogin = (user) => {
      this.setState({
        user,
      });
      localStorage.setItem('user', JSON.stringify(user));
    };

    handleLogout = () => {
      this.setState({
        user: {},
      });
      localStorage.removeItem('user');
    };

    alertVer = (alertBaslik, alertMesaj, alertType) => {
      this.setState({
        alertShow: true,
        alertMesaj,
        alertBaslik,
        alertType,
      });
    }

    closeAlert = () => {
      this.setState({
        alertShow: false,
      });
    }

    showAlert = () => {
      const {
        alertShow, alertMesaj, alertBaslik, alertType,
      } = this.state;
      return (
        <Modal show={alertShow}>
          <SuccessModal
            alertMesaj={alertMesaj}
            alertBaslik={alertBaslik}
            alertType={alertType}
            closeLoginModal={this.closeAlert}
          />
        </Modal>
      );
    }

    closeAnketBilgi = () => {
      localStorage.setItem('anketShow', false);
      this.setState({
        anketShow: false,
      });
    }

    render() {
      const { user, continueWithUser, anketShow } = this.state;
      const nextProps = {
        ...this.props,
        anketShow,
        user,
        continue: continueWithUser,
        handleLogin: this.handleLogin,
        alertVer: this.alertVer,
      };
      return (
        <>
          <Header
            sectionHeading={sectionHeading}
            user={user}
            handleLogin={this.handleLogin}
            handleLogout={this.handleLogout}
            alertVer={this.alertVer}
            anketShow={anketShow}
            handleAnketClose={this.closeAnketBilgi}
          />
          {this.showAlert()}
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <WrappedComponent {...nextProps} />
          <Footer />
        </>
      );
    }
  }

  HeaderWithUserData.propTypes = {
    user: PropTypes.objectOf.isRequired,
    state: PropTypes.objectOf.isRequired,
  };

  return HeaderWithUserData;
}
export default withHeader;
