import React from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import LoginModal from './Modal';
import logo from '../../images/logo_TTE.png';
import './HeaderNav.scss';
import { Button } from '../../stories/Buttons/Button';
import { NavbarSb } from '../../stories/Navbar/Navbar';
import AnketModal from './Modal/AnketModal';

const HeaderNav = (props) => {
  const [isGirisModalOpen, setGirisModalOpen] = React.useState(false);
  const [isAnketModalOpen, setAnketModalOpen] = React.useState(false);
  const {
    handleLogout, user, handleLogin, alertVer,
  } = props;

  const handleOpenLogin = () => {
    setGirisModalOpen(true);
    // setAnketModalOpen(true);
  };
  const handleCloseLoginModal = () => {
    setGirisModalOpen(false);
  };
  const handleLogoutLocal = () => (event) => {
    event.preventDefault(true);
    handleLogout();
  };
  const kullaniciGoster = () => {
    if (user && user.sifreId) {
      return (
        <DropdownButton menuAlign="right" id="dropdown-basic-button" title={user.adSoyad} className="ml-4">
          <Dropdown.Item href="/firmaBilgileri">Profil</Dropdown.Item>
          <Dropdown.Item href="#" onClick={handleLogoutLocal()}>Çıkış</Dropdown.Item>
        </DropdownButton>
      );
    }
    return (
      <Button onClick={handleOpenLogin} addClass="ml-4" text="Giriş Yap" />
    );
  };
  const handleLoginLocal = (userLocal) => {
    handleLogin(userLocal);
  };

  const handleCloseAnketModal = () => {
    sessionStorage.setItem('isAnketModalOpen', false);
    setAnketModalOpen(false);
  };

  const isAnketModalOpenFunc = () => {
    const isAnketModalOpenStorage = sessionStorage.getItem('isAnketModalOpen');
    if (isAnketModalOpenStorage === null) {
      return isAnketModalOpen;
    }
    return false;
  };

  return (
    <div>
      <Modal show={isGirisModalOpen} onHide={handleCloseLoginModal}>
        <LoginModal
          onSubmit={handleLogin}
          alertVer={alertVer}
          closeLoginModal={handleCloseLoginModal}
          handleLogin={handleLoginLocal}
        />
      </Modal>
      <Modal show={isAnketModalOpenFunc()} onHide={handleCloseAnketModal} size="lg">
        <Modal.Body>
          <AnketModal
            closeLoginModal={handleCloseAnketModal}
          />
        </Modal.Body>
      </Modal>
      <div className="topBar">
        <div className="container">T.C. Ticaret Bakanlığı Ticarette Teknik Engeller Bilgi Ve Bildirim Merkezi</div>
      </div>

      <NavbarSb img={logo} button={kullaniciGoster()} />

    </div>
  );
};

HeaderNav.propTypes = {
  handleLogout: PropTypes.objectOf.isRequired,
  user: PropTypes.objectOf.isRequired,
  handleLogin: PropTypes.objectOf.isRequired,
  alertVer: PropTypes.objectOf.isRequired,
};

export default HeaderNav;
