import React from 'react';
import './snippet.scss';
import PropTypes from 'prop-types';

export const SnippetCard = ({
  title, text, img, link,
}) => (
  <div className="text-center">
    <img src={img} alt="icon" />
    <h4 className="mt-4">
      {title}
    </h4>
    {link === undefined ? (
      <span className="text-black">{text}</span>
    ) : (
      <a href={link} target="_blank" className="card-link" rel="noreferrer">
        {text}
      </a>
    )}
  </div>
);

SnippetCard.defaultProps = {
  link: undefined,
};

SnippetCard.propTypes = {
  link: PropTypes.string,
  img: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default SnippetCard;
