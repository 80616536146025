import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { getIcerik } from '../service/service';
import { RightPage } from '../stories/Pages/Pages';
import { TeknikEngelBox, TeknikEngelSingle } from '../stories/SideBox/SideBox';
import withHeader from './withHeader';

function TeknikEngelNedir(props) {
  const [aktifMenu, setAktifMenu] = React.useState(1);
  const [menu1Icerik, setMenu1Icerik] = React.useState({});
  const [menu2Icerik, setMenu2Icerik] = React.useState({});
  const [menu3Icerik, setMenu3Icerik] = React.useState({});
  const [menu4Icerik, setMenu4Icerik] = React.useState({});
  const [menu5Icerik, setMenu5Icerik] = React.useState({});
  const { alertVer } = props;
  useEffect(() => getIcerik('teknik-engel-nedir')
    .then((res) => {
      if (res && res.data) {
        setMenu1Icerik(res.data);
      }
    })
    .then(() => {
      getIcerik('dto-ticarette-teknik-engeller-anlasmasi').then((res) => {
        if (res && res.data) {
          setMenu2Icerik(res.data);
        }
      });
    })
    .then(() => {
      getIcerik('bildirim-sistemi').then((res) => {
        if (res && res.data) {
          setMenu3Icerik(res.data);
        }
      });
    })
    .then(() => {
      getIcerik(
        'dto-ticarette-teknik-engeller-bilgi-ve-bildirim-merkezi',
      ).then((res) => {
        if (res && res.data) {
          setMenu4Icerik(res.data);
        }
      });
    })
    .then(() => {
      getIcerik('ticarette-teknik-engeller-internet-sitesi').then((res) => {
        if (res && res.data) {
          setMenu5Icerik(res.data);
        }
      });
    })
    .catch(() => alertVer('Veriler çekilirken sorunla karşılaşıldı', '', 'danger')), [props]);

  const handleAktifMenuClick = (menuIndex) => () => {
    setAktifMenu(menuIndex);
  };
  const renderAktifMenu = () => {
    switch (aktifMenu) {
      case 1:
        return (
          <RightPage item={menu1Icerik} />
        );
      case 2:
        return (
          <RightPage item={menu2Icerik} />
        );
      case 3:
        return (
          <RightPage item={menu3Icerik} />
        );
      case 4:
        return (
          <RightPage item={menu4Icerik} />
        );
      case 5:
        return (
          <RightPage item={menu5Icerik} />
        );
      default:
        return <></>;
    }
  };
  return (
    <div>
      <div className="page-content">
        <div className="container pt-4">
          <div className="row">
            <div className="col-lg-4 cardSideBox">
              <TeknikEngelBox>
                <TeknikEngelSingle
                  link="#"
                  onClick={handleAktifMenuClick(1)}
                  text="Ticarette Teknik Engeller"
                  aktif={aktifMenu}
                  numara={1}
                />
                <TeknikEngelSingle
                  link="#"
                  onClick={handleAktifMenuClick(2)}
                  text="DTÖ Ticarette Teknik Engeller Anlaşması"
                  aktif={aktifMenu}
                  numara={2}
                />
                <TeknikEngelSingle
                  link="#"
                  onClick={handleAktifMenuClick(3)}
                  text="Bildirim Sistemi"
                  aktif={aktifMenu}
                  numara={3}
                />
                <TeknikEngelSingle
                  link="#"
                  onClick={handleAktifMenuClick(4)}
                  text="DTÖ Ticarette Teknik Engeller Bilgi ve Bildirim Merkezi"
                  aktif={aktifMenu}
                  numara={4}
                />
                <TeknikEngelSingle
                  link="#"
                  onClick={handleAktifMenuClick(5)}
                  text="Ticarette Teknik Engeller İnternet Sitesi"
                  aktif={aktifMenu}
                  numara={5}
                />
              </TeknikEngelBox>
            </div>

            <div className="col-lg-8 p-0">
              {renderAktifMenu()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

TeknikEngelNedir.propTypes = {
  alertVer: PropTypes.objectOf.isRequired,
};

export default withHeader(TeknikEngelNedir, 'Teknik Engel Nedir?');
