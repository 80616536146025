import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { getIcerik } from '../service/service';
import { RightPage } from '../stories/Pages/Pages';
import { TeknikEngelBox, TeknikEngelSingle } from '../stories/SideBox/SideBox';
import withHeader from './withHeader';

function TeknikEngelAsmaAnahtari(props) {
  const [aktifMenu, setAktifMenu] = React.useState(1);
  const [menu1Icerik, setMenu1Icerik] = React.useState({});
  const [menu2Icerik, setMenu2Icerik] = React.useState({});
  const [menu3Icerik, setMenu3Icerik] = React.useState({});
  const [menu4Icerik, setMenu4Icerik] = React.useState({});
  const { alertVer } = props;
  useEffect(() => getIcerik(
    'ab-uyesi-ulkelerle-ayni-teknik-mevzuati-uyguladigimizi-biliyor-musunuz',
  )
    .then((res) => {
      if (res && res.data) {
        setMenu1Icerik(res.data);
      }
    })
    .then(() => {
      getIcerik('urun-pasaportu-ce-isareti').then((res) => {
        if (res && res.data) {
          setMenu2Icerik(res.data);
        }
      });
    })
    .then(() => {
      getIcerik(
        'ab-uyesi-ulkeler-ile-farkli-mevzuatin-uygulandigi-duzenlenmis-alandaki-haklariniz-nedir',
      ).then((res) => {
        if (res && res.data) {
          setMenu3Icerik(res.data);
        }
      });
    })
    .then(() => {
      getIcerik(
        'ab-uyesi-bir-ulkeye-ihrac-etmek-istediginiz-urunun-gelecekte-hangi-kurallara-tabi-olacagini-ogrenmek-ister-misiniz',
      ).then((res) => {
        if (res && res.data) {
          setMenu4Icerik(res.data);
        }
      });
    })
    .catch(() => alertVer(
      'Veriler çekilirken bir sorunla karşılaşıldı.',
      '',
      'danger',
    )), [props]);
  const handleAktifMenuClick = (menuIndex) => () => {
    setAktifMenu(menuIndex);
  };
  const renderAktifMenu = () => {
    switch (aktifMenu) {
      case 1:
        return (
          <RightPage item={menu1Icerik} />
        );
      case 2:
        return (
          <RightPage item={menu2Icerik} />
        );
      case 3:
        return (
          <RightPage item={menu3Icerik} />
        );
      case 4:
        return (
          <RightPage item={menu4Icerik} />
        );
      default:
        return <></>;
    }
  };
  return (
    <div>
      <div className="page-content">
        <div className="container pt-4">
          <div className="row">
            <div className="col-lg-4 cardSideBox">
              <TeknikEngelBox>

                <TeknikEngelSingle
                  onClick={handleAktifMenuClick(1)}
                  link="#"
                  text="AB Üyesi Ülkelerle Aynı Teknik Mevzuatı Uyguladığımızı Biliyor Musunuz?"
                  aktif={aktifMenu}
                  numara={1}
                />
                <TeknikEngelSingle
                  onClick={handleAktifMenuClick(2)}
                  link="#"
                  text=" Ürün Pasaportu: CE İşareti"
                  aktif={aktifMenu}
                  numara={2}
                />
                <TeknikEngelSingle
                  onClick={handleAktifMenuClick(3)}
                  link="#"
                  text="AB Üyesi Ülkeler ile Farklı Mevzuatın Uygulandığı Düzenlenmiş Alandaki Haklarınız Nedir?"
                  aktif={aktifMenu}
                  numara={3}
                />
                <TeknikEngelSingle
                  onClick={handleAktifMenuClick(4)}
                  link="#"
                  text="AB Üyesi Bir Ülkeye İhraç Etmek İstediğiniz Ürünün Gelecekte Hangi Kurallara Tabi Olacağını Öğrenmek İster Misiniz?"
                  aktif={aktifMenu}
                  numara={4}
                />
              </TeknikEngelBox>
            </div>
            <div className="col-lg-8 p-0 ">
              {renderAktifMenu()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

TeknikEngelAsmaAnahtari.propTypes = {
  alertVer: PropTypes.objectOf.isRequired,
};

export default withHeader(
  TeknikEngelAsmaAnahtari,
  'Teknik Engel Aşma Anahtarı',
);
