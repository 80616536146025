import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

export default function withScrollToTop(WrappedComponent) {
  class ScrollToTop extends Component {
    componentDidUpdate(prevProps) {
      const { location } = this.props;
      if (location.hash) {
        const id = location.hash.substr(1);
        if (!id) {
          return;
        }
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      } else if (location.pathname !== prevProps.location.pathname) {
        window.scrollTo(0, 0);
      }
    }

    render() {
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <WrappedComponent {...this.props} />;
    }
  }

  ScrollToTop.propTypes = {
    location: PropTypes.objectOf.isRequired,
  };

  return withRouter(ScrollToTop);
}
