import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha,
} from 'react-simple-captcha';
import { register } from '../../../service/service';

const SignupModal = (props) => {
  const [email, setEmail] = useState('');
  const [firmaAd, setFirmaAd] = useState('');
  const [ad, setAd] = useState('');
  const [soyad, setSoyad] = useState('');
  const [pass, setPass] = useState('');
  const [rePass, setRepass] = useState('');
  const [adProblem, setAdProblem] = useState(false);
  const [soyadProblem, setSoyadProblem] = useState(false);
  const [firmaAdProblem, setFirmaAdProblem] = useState(false);
  const [emailProblem, setEmailProblem] = useState(false);
  const [passwordProblem, setPasswordProblem] = useState(false);
  const [rePasswordProblem, setRePasswordProblem] = useState(false);
  const [captchaProblem, setCaptchaProblem] = useState(false);
  const [emailMessage, setEmailMessage] = useState('');
  const [passMessage, setPassMessage] = useState('');
  const { closeLoginModal, changeModalState } = props;

  useEffect(() => {
    loadCaptchaEnginge(6);
  }, []);

  const handleCloseLogin = () => {
    closeLoginModal();
  };
  const handleModalChange = (newState) => (event) => {
    event.preventDefault(true);
    changeModalState(newState);
  };
  const isValidEmail = (val) => {
    const regEmail = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return (regEmail.test(val));
  };

  const handleInputChange = (fieldType) => (event) => {
    switch (fieldType) {
      case 'email':
        setEmail(event.target.value);
        break;
      case 'firmaAd':
        setFirmaAd(event.target.value);
        break;
      case 'ad':
        setAd(event.target.value);
        break;
      case 'soyad':
        setSoyad(event.target.value);
        break;
      case 'pass':
        setPass(event.target.value);
        break;
      case 'rePass':
        setRepass(event.target.value);
        break;
      default:
        break;
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault(true);
    const userCaptchaValue = document.getElementById('user_captcha_input').value;

    if (validateCaptcha(userCaptchaValue, false) === true) {
      setCaptchaProblem(false);
    } else {
      setCaptchaProblem(true);
      return;
    }
    if (!email) {
      setEmailMessage('Eposta alanı boş olamaz.');
    } else if (!isValidEmail(email)) {
      setEmailProblem(true);
      setEmailMessage('Lütfen eposta adresi giriniz.');
      return;
    }

    if (!pass) {
      setPassMessage('Şifre alanı boş olamaz.');
    } else if (pass.localeCompare(rePass) !== 0) {
      setPasswordProblem(true);
      setPassMessage('Şifreler uyuşmuyor.');
    } else {
      setPasswordProblem(false);
    }
    setAdProblem(!ad);
    setSoyadProblem(!soyad);
    setEmailProblem(!email);
    setRePasswordProblem(!rePass);
    setFirmaAdProblem(!firmaAd);

    if (!email || !pass || !rePass || !firmaAd || !ad || !soyad || pass !== rePass) {
      return;
    }
    const user = {
      firmaAd,
      adSoyad: `${ad} ${soyad}`,
      nick: email,
      pass,
    };
    console.log(user);
    register(user)
      .then((res) => {
        console.log(res);
        if (res && res === 'Aktivasyon Linki Mail Adresinize Gönderilmi?tir') {
          // props.alertVer('Aktivasyon Linki Mail Adresinize Gönderilmiştir', '', 'success');
          // props.closeLoginModal();
          changeModalState(3);
        } else {
          setEmailProblem(true);
          setEmailMessage('Kayıt sırasında bir hata oluştu.');
        }
      })
      .catch(() => {
        setEmailProblem(true);
        setEmailMessage('Bağlantıda bir sorun oluştu.');
      });
  };
  return (
    // <!-- Modal Login -->
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="registerLabel">Üye Ol</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleCloseLogin}>
          <span aria-hidden="false">&times;</span>
        </button>
      </div>
      <div className="modal-body p-3">
        <form id="register-form" method="post" noValidate>
          <div className="form-group">
            <input
              id="form_name"
              type="text"
              name="name"
              className={`form-control 
                    ${adProblem && 'is-invalid'}`}
              placeholder="Adınız"
              onChange={handleInputChange('ad')}
            />
            {adProblem
                && (
                <div className="invalid-feedback">
                  Ad alanı boş olamaz.
                </div>
                )}
          </div>
          <div className="form-group">
            <input
              id="form_name"
              type="text"
              name="name"
              className={`form-control 
                    ${soyadProblem && 'is-invalid'}`}
              placeholder="Soyadınız"
              onChange={handleInputChange('soyad')}
            />
            {soyadProblem
                && (
                <div className="invalid-feedback">
                  Soyad alanı boş olamaz.
                </div>
                )}
          </div>
          <div className="form-group">
            <input
              id="form_name"
              type="text"
              name="name"
              className={`form-control 
                    ${emailProblem && 'is-invalid'}`}
              placeholder="E-Posta"
              onChange={handleInputChange('email')}
            />
            {emailProblem
                && (
                <div className="invalid-feedback">
                  {emailMessage}
                </div>
                )}
          </div>
          <div className="form-group">
            <input
              id="form_name"
              type="text"
              name="name"
              placeholder="Firmanız"
              className={`form-control 
                    ${firmaAdProblem && 'is-invalid'}`}
              onChange={handleInputChange('firmaAd')}
            />
            {firmaAdProblem
                && (
                <div className="invalid-feedback">
                  Firma adı alanı boş olamaz.
                </div>
                )}
          </div>
          <div className="form-group">
            <input
              id="form_name"
              type="password"
              name="name"
              placeholder="Şifre"
              className={`form-control 
                    ${passwordProblem && 'is-invalid'}`}
              onChange={handleInputChange('pass')}
            />
            {passwordProblem
                && (
                <div className="invalid-feedback">
                  {passMessage}
                </div>
                )}
          </div>
          <div className="form-group">
            <input
              id="form_name"
              type="password"
              name="name"
              placeholder="Şifre Tekrar"
              className={`form-control 
                    ${rePasswordProblem && 'is-invalid'}`}
              onChange={handleInputChange('rePass')}
            />
            {rePasswordProblem
                && (
                <div className="invalid-feedback">
                  Şifre Tekrar alanı boş olamaz.
                </div>
                )}
          </div>
          <div className="form-group">
            <LoadCanvasTemplate reloadText="Metni değiştir" />
            <input
              id="user_captcha_input"
              name="user_captcha_input"
              type="text"
              placeholder="Resimdekileri giriniz"
              className={`form-control 
                    ${captchaProblem && 'is-invalid'}`}
              onChange={handleInputChange('rePass')}
            />
            {captchaProblem
                && (
                <div className="invalid-feedback">
                  Lütfen aynı şekilde yazınız
                </div>
                )}
          </div>
          <div className="form-group mt-4 mb-4">
            <button type="button" className="btn btn-primary btn-block" onClick={handleSubmit}><span>Üye Ol</span></button>
          </div>
        </form>
        <div className="d-flex align-items-center text-center justify-content-center mt-4">
          <span className="text-muted mr-1">Zaten bir üye misiniz?</span>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a href="#" className="card-link" onClick={handleModalChange(0)}>Giriş Yap</a>
        </div>
      </div>
    </div>
  );
};

SignupModal.propTypes = {
  closeLoginModal: PropTypes.objectOf.isRequired,
  changeModalState: PropTypes.objectOf.isRequired,
};

export default SignupModal;
