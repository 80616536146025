import React from 'react';
import PropTypes from 'prop-types';
import './sideBox.scss';

const DtoSideBoxDefault = ({
  title, children, addClass,
}) => (
  <div className={`card cardSideBoxTableComponent ${addClass}`}>
    <div className="card-header">
      {title}
    </div>
    {children}
  </div>
);

DtoSideBoxDefault.propTypes = {
  title: PropTypes.string,
  addClass: PropTypes.string,
  children: PropTypes.element,
};

DtoSideBoxDefault.defaultProps = {
  title: 'title',
  addClass: '',
  children: undefined,
};

//----------------------------------------------------------------
export const DtoSideBox = ({
  title, children, addClass,
}) => (
  <DtoSideBoxDefault title={title} addClass={addClass}>
    <div className="card-body">{children}</div>
  </DtoSideBoxDefault>
);

DtoSideBox.propTypes = {
  title: PropTypes.string,
  addClass: PropTypes.string,
  children: PropTypes.element,
};

DtoSideBox.defaultProps = {
  title: 'title',
  addClass: '',
  children: undefined,
};

//----------------------------------------------------------------
export const DtoSideBoxList = ({
  title, addClass, children,
}) => (
  <DtoSideBoxDefault title={title} addClass={addClass}>
    <div className="card-body" style={{ fontSize: '0.8rem' }}>
      {children}
    </div>
  </DtoSideBoxDefault>
);

DtoSideBoxList.propTypes = {
  title: PropTypes.string,
  addClass: PropTypes.string,
  children: PropTypes.element,
};

DtoSideBoxList.defaultProps = {
  title: 'title',
  children: undefined,
  addClass: '',
};

//----------------------------------------------------------------
export const TeknikEngelSingle = ({
  link, text, numara, aktif, ...props
}) => {
  const { onClick } = props;

  return (
    <a id="teknikEngel" onClick={onClick} href={link} className={`list-group-item  list-group-item-action ${aktif === numara && 'active'}`}>
      {text}
    </a>
  );
};

TeknikEngelSingle.propTypes = {
  title: PropTypes.string,
  link: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
  numara: PropTypes.number.isRequired,
  aktif: PropTypes.number.isRequired,
};

TeknikEngelSingle.defaultProps = {
  text: 'text',
  title: 'title',
  link: '#',
  onClick: undefined,
};

//----------------------------------------------------------------
export const TeknikEngelBox = ({ children }) => (
  <div className="list-group">
    {children}
  </div>
);

TeknikEngelBox.propTypes = {
  children: PropTypes.element,
};

TeknikEngelBox.defaultProps = {
  children: undefined,
};
