import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha,
} from 'react-simple-captcha';
import { postIletisimFormu } from '../service/service';
import adres from '../images/adres.svg';
import telefon from '../images/telefon.svg';
import eposta from '../images/eposta.svg';
import withHeader from './withHeader';
import { Button } from '../stories/Buttons/Button';
import { Input, Textarea } from '../stories/Form/Form';
import { SnippetCard } from '../stories/SnippetCard/Snippet';

function Iletisim(props) {
  useEffect(() => {
    loadCaptchaEnginge(6);
  }, []);
  const [isim, setIsim] = React.useState('');
  const [soyIsim, setSoyIsim] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [konu, setKonu] = React.useState('');
  const [mesaj, setMesaj] = React.useState('');
  const [isimProblem, setIsimProblem] = useState(false);
  const [soyisimProblem, setSoyisimProblem] = useState(false);
  const [emailProblem, setEmailProblem] = useState(false);
  const [konuProblem, setKonuProblem] = useState(false);
  const [mesajProblem, setMesajProblem] = useState(false);
  const [captchaProblem, setCaptchaProblem] = useState(false);
  const [emailMessage, setEmailMessage] = useState(false);
  const { user, alertVer } = props;

  const handleInputChange = (fieldType) => (event) => {
    switch (fieldType) {
      case 'isim':
        setIsim(event.target.value);
        break;
      case 'soyisim':
        setSoyIsim(event.target.value);
        break;
      case 'email':
        setEmail(event.target.value);
        break;
      case 'konu':
        setKonu(event.target.value);
        break;
      case 'mesaj':
        setMesaj(event.target.value);
        break;
      default:
    }
  };
  const isValidEmail = (val) => {
    const regEmail = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regEmail.test(val);
  };
  const handleSubmit = (event) => {
    event.preventDefault(true);
    const userCaptchaValue = document.getElementById('user_captcha_input').value;
    if (validateCaptcha(userCaptchaValue, false) === true) {
      setCaptchaProblem(false);
    } else {
      setCaptchaProblem(true);
      return;
    }
    setIsimProblem(!isim);
    setSoyisimProblem(!soyIsim);
    if (!email) {
      setEmailProblem(true);
      setEmailMessage('Eposta adresi giriniz.');
    } else if (!isValidEmail(email)) {
      setEmailProblem(true);
      setEmailMessage('Geçerli bir eposta adresi giriniz.');
    } else {
      setEmailProblem(false);
    }
    setKonuProblem(!konu);
    setMesajProblem(!mesaj);

    if (!isim || !soyIsim || !email || !konu || !mesaj || !isValidEmail(email)) { return; }
    const formData = {
      ad: `${isim} ${soyIsim}`,
      mail: email,
      konu,
      mesaj,
    };
    if (user && user.id) {
      formData.firma = user.id;
    }
    postIletisimFormu(formData).then((res) => {
      if (res && res.msg && res.msg === 'Mesaj Gönderme İşlemi Başarılı') {
        alertVer('Mesaj Gönderme İşlemi Başarılı', '', 'success');
        setEmail('');
        setIsim('');
        setSoyIsim('');
        setMesaj('');
        setKonu('');
      } else {
        alertVer('Mesaj Gönderme İşlemi Başarısız', '', 'danger');
      }
    });
  };
  return (
    <div>
      <div className="page-content">
        <div className="container">
          <div className="row text-center mt-5 mb-5">
            <div className="col-lg-4 col-md-12">
              <SnippetCard
                img={adres}
                title="Adres:"
                text="Söğütözü Mahallesi 2176. Sk. No:63 06530 Çankaya/ANKARA"
              />
            </div>

            <div className="col-lg-4 col-md-6">
              <SnippetCard
                img={telefon}
                title="Telefon numarası"
                text="+90 (312) 204 89 46 - 204 89 52 - 204 89 37 - 204 97 21"
              />
            </div>

            <div className="col-lg-4 col-md-6">
              <SnippetCard
                img={eposta}
                link="mailto:tbt@ticaret.gov.tr"
                title="Bize E-posta gönderin"
                text="tbt@ticaret.gov.tr"
              />
            </div>
          </div>
          <div className="row justify-content-center mb-5 text-center">
            <div className="col-12 col-lg-8">
              <div>
                <h2 className="text-primary">İletişim Formu</h2>
                <p className="mb-0">
                  İletişime geçin ve nasıl yardımcı olabileceğimizi bize
                  bildirin. Formu doldurun, en kısa sürede sizinle iletişime
                  geçelim.
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center text-center">
            <div className="col-12 col-lg-10">
              <form id="contact-form" className="row" method="post" noValidate>
                <div className="form-group col-md-6">
                  <Input
                    onChange={handleInputChange('isim')}
                    value={isim}
                    id="isim"
                    type="text"
                    name="name"
                    check={` 
                          ${isimProblem && 'is-invalid'}`}
                    placeholder="Adınız"
                  >
                    {isimProblem && (
                      <div className="invalid-feedback">
                        Lütfen adınızı yazınız.
                      </div>
                    )}
                  </Input>
                </div>

                <div className="form-group col-md-6">
                  <Input
                    onChange={handleInputChange('soyisim')}
                    value={soyIsim}
                    id="soyisim"
                    type="text"
                    name="name"
                    check={`
                          ${soyisimProblem && 'is-invalid'}`}
                    placeholder="Soyadınız"
                  >
                    {soyisimProblem && (
                      <div className="invalid-feedback">
                        Lütfen soyadınızı yazınız.
                      </div>
                    )}
                  </Input>
                </div>

                <div className="form-group col-md-6">
                  <Input
                    onChange={handleInputChange('email')}
                    value={email}
                    id="email"
                    type="text"
                    name="name"
                    check={`
                          ${emailProblem && 'is-invalid'}`}
                    placeholder="Email"
                  >
                    {emailProblem && (
                      <div className="invalid-feedback">{emailMessage}</div>
                    )}
                  </Input>
                </div>

                <div className="form-group col-md-6">
                  <Input
                    onChange={handleInputChange('konu')}
                    value={konu}
                    id="konu"
                    type="text"
                    name="name"
                    check={` 
                          ${konuProblem && 'is-invalid'}`}
                    placeholder="Konu"
                  >
                    {konuProblem && (
                      <div className="invalid-feedback">
                        Lütfen konu yazını.
                      </div>
                    )}
                  </Input>
                </div>

                <div className="form-group col-md-12">
                  <Textarea
                    onChange={handleInputChange('mesaj')}
                    value={mesaj}
                    id="mesaj"
                    name="message"
                    check={`
                          ${mesajProblem && 'is-invalid'}`}
                    placeholder="Mesaj"
                    rows="4"
                  >
                    {mesajProblem && (
                      <div className="invalid-feedback">
                        Lütfen mesaj yazınız.
                      </div>
                    )}
                  </Textarea>
                </div>
                <div className="form-group col-md-12">
                  <LoadCanvasTemplate reloadText="Metni değiştir" />
                  <input
                    id="user_captcha_input"
                    name="user_captcha_input"
                    type="text"
                    placeholder="Resimdekileri giriniz"
                    className={`form-control 
                    ${captchaProblem && 'is-invalid'}`}
                    onChange={handleInputChange('rePass')}
                  />
                  {captchaProblem
                && (
                <div className="invalid-feedback">
                  Lütfen aynı şekilde yazınız
                </div>
                )}
                </div>

                <div className="col-md-12 text-right mt-4 mb-4">
                  <Button onClick={handleSubmit} text="Gönder" />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Iletisim.propTypes = {
  alertVer: PropTypes.objectOf.isRequired,
  user: PropTypes.objectOf.isRequired,
};

export default withHeader(
  Iletisim,
  'Ticarette Teknik Engeller Türkiye Bilgi ve Bildirim Merkezi',
);
