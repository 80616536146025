import React from 'react';
import './pages.scss';
import PropTypes from 'prop-types';

export const RightPage = ({
  addClass, item,
}) => (
  <div className={`card card-style-cnt h-600 ${addClass}`}>
    <div className="card-body">
      <div>
        <h5 className="card-title">{item.baslik}</h5>
        <div
          // eslint-disable-next-line
          dangerouslySetInnerHTML={{
            __html: item.icerikButun,
          }}
        />
      </div>
    </div>
  </div>
);

RightPage.propTypes = {
  addClass: PropTypes.string,
  item: PropTypes.node.isRequired,
};

RightPage.defaultProps = {
  addClass: '',
};

export default RightPage;
