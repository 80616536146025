import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';
import { postMevzuatTalep } from '../service/service';
import withHeader from './withHeader';
import UlkeListesi from '../components/UlkeListesi';
import FasilaBaslikListesi from '../components/FasilaBaslikListesi';

function BilgiyeUlasin(props) {
  const [secilenUlke, setSecilenUlke] = useState('');
  const [secilenUrunGrubu, setSecilenUrunGrubu] = useState(0);
  const [aciklama, setAciklama] = useState('');
  const [ulkeProblem, setUlkeProblem] = useState(false);
  const [ugProblem, setUgProblem] = useState(false);
  const [aciklamaProblem, setAciklamaProblem] = useState(false);
  const { alertVer, user } = props;

  const handleInputChange = (fieldType) => (event) => {
    switch (fieldType) {
      case 'ulke':
        setSecilenUlke(event.target.value);
        break;
      case 'urunGrubu':
        setSecilenUrunGrubu(event.target.value);
        break;
      case 'aciklama':
        setAciklama(event.target.value);
        break;
      default:
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault(true);

    setUlkeProblem(!secilenUlke);
    setAciklamaProblem(!secilenUrunGrubu);
    setUgProblem(!aciklama);
    if (!secilenUlke || !secilenUrunGrubu || !aciklama) {
      return;
    }
    const formData = {
      ulkeId: secilenUlke,
      urun: secilenUrunGrubu,
      mesaj: aciklama,
      firma: user.id,
    };
    postMevzuatTalep(formData)
      .then((res) => {
        if (res && res === '1') {
          alertVer('Talep başarıyla kaydedildi.', 'Talebiniz kaydedilmiş olup talebinize en kısa sürede cevap verilecektir.', 'success');
          setSecilenUlke('');
          setSecilenUrunGrubu(0);
          setAciklama('');
        } else {
          alertVer('Talep yapılamadı.', '', 'danger');
        }
      })
      .catch((err) => {
        alertVer('Talep yapılamadı', err, 'danger');
      });
  };
  return (
    <div>
      {/* eslint-disable-next-line react/destructuring-assignment */}
      { props.continue && (!user || !user.sifreId) && <Redirect to="/" />}
      <div className="page-content">
        <div className="container">

          <div className="row justify-content-center mt-4">
            <div className="col-12 col-lg-10" />
          </div>

          <div className="row justify-content-center mb-2 text-center">
            <div className="col-12 col-lg-9">
              <p className="mt-2">
                İhracat gerçekleştirmek istediğiniz ülkelerin mevzuat ve uygulamaları hakkında
                ihtiyaç duyduğunuz bilgileri aşağıdaki formu doldurmak suretiyle bildirebilirsiniz.
                Formun doldurulması öncesinde
                <Link to="/dunya" className="card-link">“Ülkeler İtibariyle Teknik Engeller”</Link>
                bölümünde yer alan ülke bilgilerini incelemenizde fayda bulunmaktadır.
                Formun tarafımıza iletilmesi sonrasında ilgili ülke yetkili kuruluşlarıyla
                temas kurulmak suretiyle talebiniz en kısa sürede cevaplandırılacaktır.
              </p>
            </div>
          </div>

          <div className="row justify-content-center text-center mt-2">
            <div className="col-12 col-lg-10">
              <form className="row" method="post" action="#" noValidate>
                <div className="form-group col-md-12">
                  <UlkeListesi handleInputChange={handleInputChange('ulke')} secilenUlke={secilenUlke} problem={ulkeProblem} />
                  {ulkeProblem
                  && (
                  <div className="invalid-feedback">
                    Ülke seçiniz.
                  </div>
                  )}
                </div>
                <div className="form-group col-md-12">
                  <FasilaBaslikListesi handleInputChange={handleInputChange('urunGrubu')} value={secilenUrunGrubu} problem={ugProblem} />
                  {ugProblem
                  && (
                  <div className="invalid-feedback">
                    Ürün grubu seçiniz.
                  </div>
                  )}
                </div>
                <div className="form-group col-md-12">
                  <textarea
                    onChange={handleInputChange('aciklama')}
                    name="message"
                    className={`form-control 
                    ${aciklamaProblem && 'is-invalid'}`}
                    placeholder="Açıklama"
                    rows="4"
                    value={aciklama}
                  />
                  {aciklamaProblem
                  && (
                  <div className="invalid-feedback">
                    Açıklama alanı boş olamaz.
                  </div>
                  )}
                </div>
                <div className="col-md-12 text-right mt-4 mb-4">
                  <button type="button" className="btn btn-primary" onClick={handleSubmit}><span>Gönder</span></button>
                </div>
              </form>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}

BilgiyeUlasin.propTypes = {
  alertVer: PropTypes.objectOf.isRequired,
  user: PropTypes.objectOf.isRequired,
  continue: PropTypes.objectOf.isRequired,
};

export default withHeader(BilgiyeUlasin, 'Bilgiye Ulaşın');
