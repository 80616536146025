import React, { useEffect, useState } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getKitaUlkeler } from '../service/service';
import withHeader from './withHeader';
import WorldMap from './WorldMap';
import { FlagCard } from '../stories/Cards/Cards';

function Dunya(props) {
  const [ulkeler, setUlkeler] = useState([]);
  const [ulkeId, setUlkeId] = useState(0);
  const kitaParam = useParams().kita;
  const [kitaState, setKitaState] = useState();
  const [sayfaSayisi, setSayfaSayisi] = useState(0);
  const [seciliSayfa, setSeciliSayfa] = useState(1);
  const [secilenUlke, setSecilenUlke] = useState('');
  const { alertVer } = props;

  useEffect(
    // props
    () => getKitaUlkeler()
      .then((res) => {
        if (res && res.data) {
          setUlkeler(res.data);
          const ulkeSayisi = res.data.filter((ulke) => {
            if (kitaParam) return ulke.kita === kitaParam;
            return true;
          });
          setSayfaSayisi(Math.ceil(ulkeSayisi.length / 18));
        }
      })
      .catch(() => alertVer(
        'Ülkeler yüklenirken hata ile karşılaşıldı.',
        '',
        'danger',
      )),
    [props, kitaParam],
  );

  const handleBolgeChange = () => (event) => {
    setKitaState(event.target.value);
    setUlkeId(0);
    setSeciliSayfa(1);
    const ulkeSayisi = ulkeler.filter((ulke) => {
      if (event.target.value) return ulke.kita === event.target.value;
      return true;
    });
    setSayfaSayisi(Math.ceil(ulkeSayisi.length / 18));
  };

  const handleUlkeChange = () => (event) => {
    setUlkeId(event.target.value);
    setSeciliSayfa(1);
    const ulkeSayisi = ulkeler.filter((ulke) => {
      if (event.target.value && event.target.value > 0) {
        return parseInt(ulke.id, 10) === parseInt(event.target.value, 10);
      } return true;
    });
    setSayfaSayisi(Math.ceil(ulkeSayisi.length / 18));
  };

  const ilkBildirim = () => (seciliSayfa - 1) * 18;

  const ulkeListesi = () => (
    <div className="row row-cols-2 row-cols-xl-6 row-cols-md-4 ulkelerlist">
      {ulkeler
        .filter((ulke) => {
          if (ulkeId && parseInt(ulkeId, 10) > 0) {
            return parseInt(ulke.id, 10)
            === parseInt(ulkeId, 10);
          }
          if (kitaState) return ulke.kita === kitaState;
          if (kitaParam) return ulke.kita === kitaParam;
          return true;
        })
        .slice(ilkBildirim(), ilkBildirim() + 18)
        .map((item) => (
          <div className="col p-2" key={`bildirim${item.id}`}>
            <FlagCard
              item={item}
              img={`../images/bayraklar/${item.id}.png`}
            />
          </div>
        ))}
    </div>
  );
  const sayfaDegistir = (menuIndex) => () => {
    setSeciliSayfa(menuIndex);
  };
  const sayfalar = () => {
    const sayfaTuslari = [];
    let baslangic = 0;
    let son = 0;
    if (sayfaSayisi < 6) {
      baslangic = 1;
      son = sayfaSayisi;
    } else if (seciliSayfa - 2 < 1) {
      baslangic = 1;
      son = seciliSayfa + 3 + (3 - seciliSayfa);
    } else if (seciliSayfa + 3 > sayfaSayisi) {
      baslangic = seciliSayfa - 2 - (3 - (sayfaSayisi - seciliSayfa));
      son = sayfaSayisi;
    } else {
      baslangic = seciliSayfa - 2 > 0 ? seciliSayfa - 2 : 1;
      son = seciliSayfa + 3 > sayfaSayisi ? sayfaSayisi : seciliSayfa + 2;
    }
    for (let i = baslangic; i < son + 1; i += 1) {
      sayfaTuslari.push(
        <li className={`page-item ${seciliSayfa === i && 'active'}`}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a a href="#" className="page-link" onClick={sayfaDegistir(i)}>
            {i}
          </a>
        </li>,
      );
    }
    return sayfaTuslari;
  };

  const oncekiSayfa = () => {
    if (seciliSayfa > 1) {
      <li className="page-item">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          href="#"
          className="page-link"
          onClick={sayfaDegistir(seciliSayfa - 1)}
          aria-label="Previous"
        >
          <span aria-hidden="true">&laquo;</span>
        </a>
      </li>;
    }
  };

  const sonrakiSayfa = () => {
    if (seciliSayfa < sayfaSayisi) {
      <li className="page-item">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          href="#"
          className="page-link"
          onClick={sayfaDegistir(seciliSayfa + 1)}
          aria-label="Next"
        >
          <span aria-hidden="true">&raquo;</span>
        </a>
      </li>;
    }
  };

  const ulkeSec = (kod) => {
    let ulke;
    if (kod) {
      ulke = ulkeler.filter((item) => item.mapId === kod);
    }
    if (ulke && ulke.length > 0) {
      setSecilenUlke(ulke[0].link);
    }
  };

  const navigasyon = () => (
    <ul className="pagination">
      {oncekiSayfa()}
      {sayfalar()}
      {sonrakiSayfa()}
    </ul>
  );

  return (
    <div>
      {secilenUlke && <Redirect to={`/ulke/${secilenUlke}`} />}
      <div className="page-content">
        <div className="container pt-4">
          <div className="row">
            <div className="col">
              <div className="card">
                <div className="card-body">
                  {/* <!-- Nav tabs --> */}
                  <ul
                    className="nav nav-pills flex-column flex-sm-row tabs justify-content-center mb-4"
                    id="myTab"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link active"
                        id="home-tab"
                        data-toggle="tab"
                        href="#home"
                        role="tab"
                        aria-controls="home"
                        aria-selected="true"
                      >
                        Liste Görünümü
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link"
                        id="profile-tab"
                        data-toggle="tab"
                        href="#profile"
                        role="tab"
                        aria-controls="profile"
                        aria-selected="false"
                      >
                        Harita Görünümü
                      </a>
                    </li>
                  </ul>
                  {/* <!-- Tab panes --> */}
                  <div className="tab-content">
                    {/* <!-- liste görünümü --> */}
                    <div
                      className="tab-pane active"
                      id="home"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                    >
                      <form>
                        <div className="row form-group justify-content-center">
                          <div className="col-12 col-lg-4 col-md-6">
                            <select
                              className="form-control "
                              id="exampleFormControlSelect1"
                              value={
                                kitaState || kitaParam || ''
                              }
                              onChange={handleBolgeChange()}
                            >
                              <option value="">Bölge Seç</option>
                              <option value="AF">Afrika</option>
                              <option value="AS">Asya</option>
                              <option value="EU">Avrupa</option>
                              <option value="AU">Avustralya</option>
                              <option value="SA">Güney Amerika</option>
                              <option value="NA">Kuzey Amerika</option>
                            </select>
                          </div>
                          <div className="col-12 col-lg-4 col-md-6">
                            <select
                              className="form-control"
                              id="exampleFormControlSelect1"
                              onChange={handleUlkeChange()}
                              value={ulkeId}
                            >
                              <option value="0">Ülke Seç</option>
                              {ulkeler
                                .filter((ulke) => {
                                  if (kitaState) return ulke.kita === kitaState;
                                  if (kitaParam) return ulke.kita === kitaParam;
                                  return true;
                                })
                                .map((item) => (
                                  <option
                                    value={item.id}
                                    key={`ulke${item.id}`}
                                  >
                                    {item.ad}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                      </form>
                      {/* <!-- ülke listeleme start --> */}
                      <div className="container">
                        {ulkeListesi()}
                        <div className="row pageList">
                          <div className="col-12 col-lg-6">
                            <p className="lead">Sıralama: A’dan Z’ye</p>
                          </div>
                          <div className="col-12 col-lg-6">
                            <nav
                              className="float-right"
                              aria-label="Page navigation example"
                            >
                              {navigasyon()}
                            </nav>
                          </div>
                        </div>
                      </div>
                      {/* <!-- ülke listeleme end --> */}
                    </div>
                    <div
                      className="tab-pane"
                      id="profile"
                      role="tabpanel"
                      aria-labelledby="profile-tab"
                    >
                      <WorldMap ulkeSec={ulkeSec} ulkeler={ulkeler} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Dunya.propTypes = {
  alertVer: PropTypes.objectOf.isRequired,
};

export default withHeader(Dunya, 'Ülkeler İtibariyle Teknik Engeller');
