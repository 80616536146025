import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import withHeader from './withHeader';
import { getDuyurular } from '../service/service';

function Duyurular(props) {
  const [duyurular, setDuyurular] = useState([]);
  const [aktifMenu, setAktifMenu] = useState(1);
  const { duyuruId } = useParams();
  const { alertVer } = props;
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => getDuyurular()
    .then((res) => {
      if (res && res.data) {
        setDuyurular(res.data);
        const ids = res.data.map((item) => item.id);
        const index = ids.indexOf(parseInt(duyuruId, 10));
        if (index > 0) {
          setAktifMenu(index + 1);
        }
      }
    })
    .catch((err) => alertVer('Duyurular yüklenirken hata ile karşılaşıldı', err, 'danger')), [props, duyuruId]);

  const handleAktifMenuClick = (menuIndex) => () => {
    setAktifMenu(menuIndex);
  };
  console.log(duyurular);
  return (
    <div>
      <div className="page-content">
        <div className="container">
          <div className="row justify-content-center text-center mt-5">
            <div className="col-12 col-lg-10">
              <div id="accordion" className="accordion">
                {duyurular.map((item, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div className="card border-0 active mb-2" key={index}>
                    <div className="card-header border mb-0 bg-transparent">
                      <h6 className="mb-0">
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a href="#" className="text-dark" data-toggle="collapse" data-parent="#accordion" onClick={handleAktifMenuClick(index + 1)} aria-expanded="true">{item.baslik}</a>
                      </h6>
                    </div>
                    <div id={`collapse${index + 1}`} className={`collapse ${aktifMenu === index + 1 && 'show'}`} data-parent="#accordion">
                      <div
                        className="card-body text-muted"
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                          __html: item.icerikButun,
                        }}
                      />
                      {item.belgeVar && (
                      <a href={`${backendUrl}downloadDuyuru/${item.id}`} type="button" download>
                        Duyuru dokümanını indir
                      </a>
                      )}

                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Duyurular.propTypes = {
  alertVer: PropTypes.objectOf.isRequired,
};

export default withHeader(Duyurular, 'Duyurular');
