import React from 'react';
import PropTypes from 'prop-types';
import './footer.scss';
import logo from '../../images/logo_TTE_white.png';
import footerBg from '../../images/footerbg.svg';
import topImage from '../../images/img_footer.png';

/* Footer Icon elemanları bunu kullanıyor */
export const FooterElementDefault = ({
  addClass, icon, children,
}) => (
  <li className={`footerLi list-unstyled ${addClass}`}>
    <span className={`${icon}`} />
    {children}
  </li>
);

FooterElementDefault.propTypes = {
  icon: PropTypes.string,
  addClass: PropTypes.string,
  children: PropTypes.element,
};

FooterElementDefault.defaultProps = {
  addClass: '',
  icon: undefined,
  children: undefined,
};

export const FooterIconElement = ({
  addClass, icon, text,
}) => (
  <FooterElementDefault addClass={addClass} icon={icon}>
    {text}
  </FooterElementDefault>
);

FooterIconElement.propTypes = {
  text: PropTypes.string.isRequired,
  addClass: PropTypes.string,
  icon: PropTypes.string.isRequired,
};

FooterIconElement.defaultProps = {
  addClass: '',
};

export const FooterIconLinkElement = ({
  addClass, icon, text, link,
}) => (
  <FooterElementDefault addClass={addClass} icon={icon}>
    <a rel="noopener noreferrer" href={`${link}`} target="_blank" className="card-link">
      {text}
    </a>
  </FooterElementDefault>
);

FooterIconLinkElement.propTypes = {
  text: PropTypes.string.isRequired,
  link: PropTypes.string,
  icon: PropTypes.string.isRequired,
  addClass: PropTypes.string,
};

FooterIconLinkElement.defaultProps = {
  link: '#',
  addClass: '',
};

export const FooterRightBox = ({ addClass }) => (
  <div className={`col-12 col-lg-6 col-xl-6 ${addClass}`}>
    <ul className="pl-0">
      <FooterIconElement
        icon="icn_Maps"
        text="Söğütözü Mahallesi 2176. Sk. No:63 06530 Çankaya/ANKARA"
      />
      <FooterIconElement
        icon="icn_tel"
        text="+90 (312) 204 89 46 - 204 89 52 - 204 89 37 - 204 97 21"
      />
      <FooterIconElement
        icon="icn_fax"
        text="+90 (312) 212 87 68 - 212 68 64"
      />
      <FooterIconLinkElement
        icon="icn_mail"
        link="mailto:tbt@ticaret.gov.tr"
        text="tbt@ticaret.gov.tr"
      />
    </ul>
  </div>
);

FooterRightBox.propTypes = {
  addClass: PropTypes.string,
};

FooterRightBox.defaultProps = {
  addClass: '',
};

export const FooterLeftİmg = () => (
  <div className="col-12 col-lg-6 col-xl-6">
    <img src={logo} alt="" />
  </div>
);

export const Copyright = ({ text, addClass }) => (
  <div className={`downBar ${addClass}`}>
    <div className="container">
      © Copyright
      {text}
    </div>
  </div>
);

Copyright.propTypes = {
  text: PropTypes.string,
  addClass: PropTypes.string,
};

Copyright.defaultProps = {
  text: 'text',
  addClass: '',
};

export const FooterBox = ({ addClass }) => (
  <footer
    className={`footer py-11 bg-primary position-relative footerBg ${addClass}`}
    data-bg-img={topImage}
    src={topImage}
  >
    <div className="shape-1" style={{ height: '150px', overflow: 'hidden' }}>
      <svg
        viewBox="0 0 500 150"
        preserveAspectRatio="none"
        style={{ height: '100%', width: '100%' }}
      >
        <path
          d="M0.00,49.98 C150.00,150.00 271.49,-50.00 500.00,49.98 L500.00,0.00 L0.00,0.00 Z"
          style={{ stroke: 'none', fill: '#fff' }}
        />
      </svg>
      <img src={footerBg} alt="" style={{ height: '100%', width: '100%' }} />
    </div>
    <div className="container">
      <div className="row">
        <FooterLeftİmg />
        <FooterRightBox />
      </div>
    </div>
    <hr />
    <Copyright
      text="2020 T.C. Ticaret Bakanlığı Ürün Güvenliği ve Denetimi Genel Müdürlüğü"
    />
  </footer>
);

FooterBox.propTypes = {
  addClass: PropTypes.string,
};

FooterBox.defaultProps = {
  addClass: '',
};
