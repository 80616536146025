import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Calendar from 'react-calendar';
import UlkeListesi from '../components/UlkeListesi';
import datepickerIcon from '../icons/icn_datepicker.svg';

const ArsivModal = (props) => {
  const [showBasTarih, setShowBasTarih] = useState(false);
  const [showBitTarih, setShowBitTarih] = useState(false);
  const [basTarih, setBasTarih] = useState(new Date());
  const [bitTarih, setBitTarih] = useState(new Date());
  const {
    handleInputChange, closeLoginModal, bildirimAra, bildirimKodu,
  } = props;

  const handleBasTarihChange = (value) => {
    setShowBasTarih(false);
    setBasTarih(value);
    handleInputChange('baslangicTarihi', value.toJSON());
  };
  const handleBitTarihChange = (value) => {
    setShowBitTarih(false);
    setBitTarih(value);
    handleInputChange('bitisTarihi', value.toISOString());
  };

  const handleCloseLogin = () => {
    closeLoginModal();
  };

  const bildirimAraLocal = () => (event) => {
    event.preventDefault(true);
    bildirimAra();
  };

  const handleInputChangeLocal = (fieldType) => (event) => {
    handleInputChange(fieldType, event.target.value);
  };
  return (
    // <!-- Modal Login -->
    <div className="modal fade" id="dtoArsiv" tabIndex="-3" aria-hidden="false">
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="dtoArsivLabel">Arşiv</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleCloseLogin}>
              <span aria-hidden="false">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <form id="arsiv-form" className="row" method="post" noValidate>
              <div className="form-group col-md-12">
                <input
                  id="anahtar_kelime"
                  type="text"
                  name="name"
                  className="form-control"
                  placeholder="Anahtar Kelime"
                  onChange={handleInputChangeLocal('anahtarKelime')}
                />
              </div>
              <div className="form-group col-md-6">
                <UlkeListesi handleInputChange={handleInputChangeLocal('bildirenUlke')} />
              </div>
              <div className="form-group col-md-6">
                <input
                  id="bildirim_kodu"
                  type="text"
                  name="name"
                  className="form-control"
                  placeholder="Bildirim Kodu"
                  required="required"
                  data-error="Name is required."
                  value={bildirimKodu}
                  onChange={handleInputChangeLocal('bildirimKodu')}
                />
              </div>

              <div className="form-group input-group col-md-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Başlangıç Tarihi"
                  readOnly
                  onFocus={() => setShowBasTarih(true)}
                  value={basTarih.toLocaleDateString('tr-TR', {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                  })}
                />
                <div className="input-group-append">
                  <span className="input-group-text">
                    <img src={datepickerIcon} style={{ height: '1.5rem' }} alt="tarih seç ikonu" />
                  </span>
                </div>
                <Calendar
                  className={showBasTarih ? '' : 'hide'}
                  value={basTarih}
                  onChange={handleBasTarihChange}
                />

              </div>
              <div className="form-group input-group col-md-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Bitiş Tarihi"
                  readOnly
                  onFocus={() => setShowBitTarih(true)}
                  value={bitTarih.toLocaleDateString('tr-TR', {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                  })}
                />
                <div className="input-group-append">
                  <span className="input-group-text">
                    <img src={datepickerIcon} style={{ height: '1.5rem' }} alt="tarih seç ikonu" />
                  </span>
                </div>
                <Calendar
                  className={showBitTarih ? '' : 'hide'}
                  value={bitTarih}
                  onChange={handleBitTarihChange}
                />
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={handleCloseLogin}>İptal</button>
            <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={bildirimAraLocal()}>Bildirim Ara</button>
          </div>
        </div>
      </div>
    </div>
  );
};

ArsivModal.propTypes = {
  handleInputChange: PropTypes.objectOf.isRequired,
  closeLoginModal: PropTypes.objectOf.isRequired,
  bildirimAra: PropTypes.objectOf.isRequired,
  bildirimKodu: PropTypes.objectOf.isRequired,
};

export default ArsivModal;
