import React from 'react';
import ButtonBootstrap from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import './button.scss';
import PropTypes from 'prop-types';

const ButtonDefault = ({
  text, to, link, addClass, ...props
}) => {
  const { onClick } = props;
  if (link !== undefined) {
    return (
      <a href={link} className={`${addClass}`} onClick={onClick}>
        {text}
      </a>
    );
  }
  if (to !== undefined) {
    return (
      <Link to={to} className={`${addClass}`} onClick={onClick}>
        {text}
      </Link>
    );
  }
  return (
    <ButtonBootstrap className={`${addClass}`} onClick={onClick}>
      {text}
    </ButtonBootstrap>
  );
};

ButtonDefault.propTypes = {
  text: PropTypes.string.isRequired,
  to: PropTypes.string,
  link: PropTypes.string,
  addClass: PropTypes.string,
  onClick: PropTypes.func,
};

ButtonDefault.defaultProps = {
  to: undefined,
  link: undefined,
  addClass: '',
  onClick: undefined,
};

export const Button = ({
  text, to, link, addClass, block, secondary, ...props
}) => {
  const { onClick } = props;
  const mode = secondary === true ? 'btn-outline-light' : 'btn-primary';
  const size = block === true ? 'btn-block' : '';
  return (
    <ButtonDefault
      addClass={`btn ${mode} ${size} ${addClass}`}
      text={text}
      to={to}
      link={link}
      onClick={onClick}
    />
  );
};

Button.propTypes = {
  text: PropTypes.string.isRequired,
  to: PropTypes.string,
  link: PropTypes.string,
  addClass: PropTypes.string,
  secondary: PropTypes.bool,
  block: PropTypes.bool,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  to: undefined,
  link: undefined,
  secondary: false,
  block: false,
  addClass: '',
  onClick: undefined,
};

export default Button;
